import React, { useState, useEffect, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { axiosProductsInstance } from "../dataFetch/axios.main";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import productData from '../data/productData';
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "../css/Recommends.css";

const Recommends = () => {
  const [recommendData, setRecommendData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(() => {
    setLoading(true);
    axiosProductsInstance
      .get("", { params: { tag: "recommended", size: 50 } })
      .then((res) => {
        setRecommendData(res.data);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="recommends">
      {/* Using data from productDta for demonstration */}
      <h3 className="recommends-title">Products You May Like</h3>
      <div className="recommends-wrapper">
        {loading ? (
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={20}
            // loop={true}
            centeredSlides={true}
            centerInsufficientSlides={true}
            centeredSlidesBounds={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            className="mySwiper"
          >
            {Array(10)
              .fill(0)
              .map((_, i) => (
                <SwiperSlide key={i} className="recommends-list">
                  <div className="recommends-image-wrapper">
                    <Skeleton width={250} height={175} />
                  </div>
                  <div className="recommends-info">
                    <div className="recommends-list-title">
                      <Skeleton width={250} height={20} />
                    </div>
                    <div>
                      <Skeleton width={50} height={20} />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        ) : (
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={20}
            // loop={true}
            centeredSlides={true}
            centerInsufficientSlides={true}
            centeredSlidesBounds={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            className="mySwiper"
          >
            {recommendData.map((product) => (
              <SwiperSlide key={product.id} className="recommends-list">
                <div className="recommends-image-wrapper">
                  <Link
                    to={`/product/${product.slug}`}
                    className="recommends-image"
                    style={{
                      backgroundImage: "url(" + product.thumbnail + ")",
                    }}
                  ></Link>
                  {product.on_sale && <span className="sale-label"></span>}
                </div>
                <div className="recommends-info">
                  <Link
                    to={`/product/${product.slug}`}
                    className="recommends-list-title"
                  >
                    {product.name}
                  </Link>
                  {/* <p>{product.description}</p> */}
                  <div>
                    <span className="price">
                      ${product.price}
                      <span className="price-uom">
                        {product.meta_data[1] && product.meta_data[1].value}
                      </span>
                    </span>
                    {product.on_sale && (
                      <span className="regular-price">
                        ${product.regular_price}
                        <span className="price-uom">
                          {product.meta_data[1] && product.meta_data[1].value}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default Recommends;
