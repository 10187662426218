import axios from "axios";

const apiUrl = "https://thefishermansmarket.ca/wp-json/";
const consumerKey = process.env.REACT_APP_API_KEY;
const consumerSecret = process.env.REACT_APP_API_SECRET;

export const axiosOrderInstance = axios.create({
  baseURL: apiUrl,
  params: {
    // _fields: 'id,title,method_title,description,enabled' Specify only these fields in the response
  },
});

export const axiosMailChimpInstance = axios.create({
  baseURL: apiUrl,
  // auth: {
  //     username: consumerKey,
  //     password: consumerSecret,
  // },
  params: {
    // _fields: 'id,title,method_title,description,enabled' Specify only these fields in the response
  },
});
