import React, { useState } from "react";
import axios from "axios";
import SignUpButton from "../components/SignUpButtom";
import { Toaster, toast } from "react-hot-toast";
import "../css/Terms.css";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://thefishermansmarket.ca/wp-json/contact/v1/submit",
        formData,
      );
      if (response.data.success) {
        toast.success("Your message has been sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      } else {
        toast.error(
          "There was an error submitting your message. Please try again.",
        );
      }
    } catch (error) {
      toast.error(
        "There was an error submitting your message. Please try again.",
      );
    }
  };

  return (
    <div className="terms">
      {/* <div className="terms-banner">
                <h2>Contact Us</h2>
            </div> */}

      <div className="terms-wrapper">
        <div className="terms-body">
          <div className="terms-body-section">
            <h3>Contact Information</h3>
            <p style={{ textAlign: "center", marginBottom: "0" }}>
              <b>Head office</b>
            </p>
            <p style={{ textAlign: "center", marginTop: "0" }}>
              336 Baker St Nelson, BC V1L 4H5
            </p>
            <p style={{ textAlign: "center", marginBottom: "0" }}>
              <b>Email</b>
            </p>
            <p style={{ textAlign: "center", marginTop: "0" }}>
              <a href="mailto:info@fmseafoods.com">info@fmseafoods.com</a>
            </p>
          </div>
          <div className="terms-body-section">
            <h3>Contact Us</h3>
            <form onSubmit={handleSubmit} className="terms-body-section-form">
              <div className="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label for="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  placeholder="Write your message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit">SEND</button>
            </form>
          </div>
        </div>
      </div>
      <Toaster position="bottom-center" reverseOrder={false} duration={4000} />
      <SignUpButton />
    </div>
  );
}
