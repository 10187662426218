import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Shopping Cart
import { useCart } from "../context/CartContext";

// Components
import EmptyCart from "../components/cart/EmptyCart";
import CartContent from "../components/cart/CartContent";
import Recommends from "../components/Recommends";
import SignUpButton from "../components/SignUpButtom";

const Cart = () => {
  // Properties
  const { cartItems } = useCart();
  // Properties
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Router Navigation
  // const navigate = useNavigate();

  return (
    <div className="cart">
      {cartItems.length === 0 ? (
        <EmptyCart />
      ) : (
        <CartContent userStatus={isLoggedIn}></CartContent>
      )}
      <Recommends />
      <SignUpButton />
    </div>
  );
};

export default Cart;
