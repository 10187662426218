import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import recipeData from "../data/recipeData";
import PopUp from "./PopUp";
import "../css/RecipeDetail.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";

import Recommends from "./Recommends";
import { get } from "react-hook-form";

export default function RecipeDetail() {
  const [recipe, setRecipe] = useState(null);
  const [recipePre, setRecipePre] = useState(null);
  const [recipeNxt, setRecipeNxt] = useState(null);
  const { recipeId } = useParams();

  useEffect(() => {
    function getRecipeById(id) {
      const parseId = parseInt(id, 10);
      const recipe = recipeData.find((recipe) => recipe.id === parseId);
      const currentIndex = recipeData.findIndex(
        (recipe) => recipe.id === parseId,
      );
      const recipePre = currentIndex > 0 ? recipeData[currentIndex - 1] : null;
      const recipeNxt =
        currentIndex < recipeData.length - 1
          ? recipeData[currentIndex + 1]
          : null;

      return {
        currentObj: recipe,
        prevObj: recipePre,
        nextObj: recipeNxt,
      };
    }

    const fetchedRecipte = getRecipeById(recipeId);
    setRecipe(fetchedRecipte.currentObj);
    setRecipePre(fetchedRecipte.prevObj);
    setRecipeNxt(fetchedRecipte.nextObj);
  }, [recipeId]);

  function ChefInfo() {
    if (!recipe.notes.chef) return null;
    if (recipe.notes.chef === "Chef Jessi") {
      return (
        <div className="recipe-notes-bio">
          <img
            src="/images/recipes/FishermansMarket_Chef_Jessi.webp"
            alt="Chef Jessi"
          />
          <h4>Chef Jessi</h4>
          <span>
            Though she’s traded her chef knives for fillet knives, Chef Jessi is
            excited to continue sharing new ways of bringing restaurant-quality
            dishes to your home kitchen using ingredients from the Fisherman’s
            Market.
          </span>
        </div>
      );
    }
    if (recipe.notes.chef === "Chef Mike") {
      return (
        <div className="recipe-notes-bio">
          <img
            src="/images/recipes/FishermansMarket_Chef_Mike_Ethical_Table.webp"
            alt="Chef Mike"
          />
          <h4>Chef Mike</h4>
          <span>
            Chef Mike Sonier spent his career as a restauranteur creating
            community-focused spaces highlighting local ingredients in both
            Eastern and Western Canada. He’s now the founder of Ethical Table
            Food Co.
          </span>
        </div>
      );
    }
  }

  if (!recipe) {
    return <p>Recipe not found.</p>;
  }

  return (
    <div className="recipe-detail">
      <div className="recipe-detail-wrapper">
        <div className="recipe-detail-info">
          <h2 className="recipe-detail-title">{recipe.title}</h2>
          <div className="recipe-detail-grid">
            <div className="detail-grid-item">
              <h3>PREP TIME</h3>
              <p>{recipe.prepTime}</p>
            </div>
            <div className="detail-grid-item">
              <h3>COOK TIME</h3>
              <p>{recipe.cookTime}</p>
            </div>
            <div className="detail-grid-item">
              <h3>SERVES</h3>
              <p>{recipe.serves}</p>
            </div>
            <div className="detail-grid-item">
              <h3>DATE</h3>
              <p>{recipe.date}</p>
            </div>
            <div className="detail-grid-item">
              <h3>AUTHOR</h3>
              <p>{recipe.author}</p>
            </div>
            <div className="detail-grid-item">
              <h3
                style={{
                  textAlign: "right",
                  fontWeight: "450",
                  color: "#173050",
                }}
              >
                <a
                  href="#facebook"
                  style={{ color: "#173050", textDecoration: "none" }}
                  onClick={() => window.print()}
                >
                  <FontAwesomeIcon icon={faPrint} />
                  &ensp;Print
                </a>
              </h3>
              <div className="detail-social-icons">
                <a
                  href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                  href={`https://twitter.com/share?url=${window.location.href}&text=${recipe.title}`}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href={`https://pinterest.com/pin/create/bookmarklet/?url=${window.location.href}&description=${recipe.title}`}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faPinterest} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="recipe-detail-image">
          <div
            className="detail-image"
            style={{ backgroundImage: "url(" + recipe.imgUrl + ")" }}
          ></div>
        </div>
      </div>

      <div className="recipe-ingredt-wrapper">
        <h3>
          <span>INGREDIENTS</span>
        </h3>
        {recipe.ingredients.map((subtitle, indexZ) => (
          <div key={indexZ} className="recipe-content">
            {subtitle.subTitle && <h4>{subtitle.subTitle}</h4>}
            <ul>
              {subtitle.list.map((items, indexY) => (
                <li key={indexY}>
                  <p>{items}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="recipe-direct-wrapper">
        <h3>
          <span>DIRECTIONS</span>
        </h3>
        {recipe.directions.map((subtitle, indexX) => (
          <div key={indexX} className="recipe-content">
            {subtitle.subTitle && <h4>{subtitle.subTitle}</h4>}
            <ol>
              {subtitle.list.map((items, indexW) => (
                <li key={indexW}>
                  <p>{items}</p>
                </li>
              ))}
            </ol>
          </div>
        ))}
      </div>

      {recipe.videoUrl && (
        <a href={recipe.videoUrl} className="recipe-video">
          Watch {recipe.notes.chef}'s create this delicious treat here!
        </a>
      )}

      <div className="recipe-notes">
        <p>{recipe.notes.description}</p>
        <ChefInfo />
      </div>

      <div className="recipe-pageturn">
        {recipePre && (
          <a href={`/recipes/${recipePre.id}`} className="turn-button-left">
            <FontAwesomeIcon icon={faChevronLeft} />
            Previous Recipe
            <span>{recipePre.title}</span>
          </a>
        )}
        {recipeNxt && (
          <a href={`/recipes/${recipeNxt.id}`} className="turn-button-right">
            Next Recipe
            <FontAwesomeIcon icon={faChevronRight} />
            <span>{recipeNxt.title}</span>
          </a>
        )}
      </div>
      <Recommends />
      <PopUp />
    </div>
  );
}
