import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CheckoutSummaryItem from "../../components/cart/CheckoutSummaryItem";
import CartContent from "../../components/cart/CartContent";
import { useCart } from "../../context/CartContext";
import { useUser } from "../../context/UserContext";
import { useCheckout } from "../../context/CheckoutContext";
import axios from "axios";
import Stepper from "awesome-react-stepper";
import validator from "validator";
import "../../css/Checkout.css";

const CustomerInfo = () => {
  // Contexts
  const {
    updateCustomerInfo,
    toggleCheckoutOption,
    state,
    dispatchCheckoutOptions,
  } = useCheckout();
  // const { cartItems, getLineItemsForWoo, getTotalItems } = useCart();
  const { isLoggedIn, userDetails } = useUser();

  // State variables
  // const [paymentGateways, setPaymentGateways] = useState([]);

  // Fetch customerInfo from localStorage if it exists, else set initial state
  const [customerInfo, setCustomerInfo] = useState(() => {
    const savedCustomerInfo = JSON.parse(
      localStorage.getItem("checkout-state"),
    )?.customerInfo;
    // Why is this console message printed twice.
    // console.log(savedCustomerInfo.customerInfo);
    // console.log("userDetails from CustomerInfo: ", userDetails)
    if (isLoggedIn && userDetails) {
      // console.log("setting customer info: ", userDetails.first_name)
      return {
        firstName: userDetails.first_name,
        lastName: userDetails.last_name,
        email: userDetails.email,
        phone: savedCustomerInfo.phone,
      };
    } else {
      return savedCustomerInfo
        ? savedCustomerInfo
        : {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          };
    }
  });
  const [checkoutOptions, setCheckoutOptions] = useState(() => {
    const savedCheckOutOptions = JSON.parse(
      localStorage.getItem("checkout-state"),
    )?.checkoutOptions;

    // Why is this console message printed twice.
    // console.log(savedCheckOutOptions);

    return savedCheckOutOptions
      ? savedCheckOutOptions
      : {
          isGuestCheckout: true,
          createAccountPostPurchase: true,
          optInPromotionalEmails: true,
          optInTextMessages: false,
          storePickup: true,
          storeDelivery: false,
          payOnDelivery: true,
        };
  });
  // Fetch guestOptions from localStorage if it exists, else set initial state
  const [guestOptions, setGuestOptions] = useState(() => {
    const savedGuestOptions = JSON.parse(
      localStorage.getItem("checkout-state"),
    )?.guestOptions;
    return savedGuestOptions
      ? savedGuestOptions
      : {
          createAccountPostPurchase: false,
          optInPromotionalEmails: true,
        };
  });

  useEffect(() => {
    // console.log("update customer info")
    updateCustomerInfo(customerInfo);
  }, [customerInfo]);

  // useEffect(() => {
  //     console.log("Changing checkoutOptions: ",checkoutOptions);
  //     toggleCheckoutOption(checkoutOptions);
  // }, [checkoutOptions])

  const handleChange = (section, key, value) => {
    // console.log("customerInfo changing")
    switch (section) {
      case "customerInfo":
        setCustomerInfo((prev) => ({ ...prev, [key]: value }));
        // updateCustomerInfo(customerInfo);
        break;
      case "checkoutOptions":
        setCheckoutOptions((prev) => ({ ...prev, [key]: value }));
        dispatchCheckoutOptions({
          type: "SET_OPT_IN_PROMOTIONAL_EMAIL",
          payload: value,
        });
        break;
      // case 'discountCodeInfo':
      //     setDiscountCodeInfo(prev => ({ ...prev, [key]: value }));
      //     break;
      // case 'guestOptions':
      //     setGuestOptions(prev => ({ ...prev, [key]: value }));
      //     break;
      // Add cases for other sections as necessary
      default:
        break;
    }
  };

  const handleBlur = (key, value) => {
    console.log("target: ", value);
    if (!validator.isEmail(value)) {
      setCustomerInfo((prev) => ({ ...prev, [key]: "" }));
      alert("Please enter a valid email address.");
    }
  };

  return (
    <div className="customer-info-form">
      <fieldset className="customer-info-fieldset">
        <legend>Customer Information</legend>
        <div className="name-container">
          <div className="form-group-checkout">
            <label htmlFor="firstName" className="required">
              First Name
            </label>
            <input
              id="firstName"
              type="text"
              className="form-control"
              value={
                userDetails ? userDetails.first_name : customerInfo.firstName
              }
              // onBlur={()=>{handleBlur("firstName", event.target)}}
              onChange={(e) =>
                handleChange("customerInfo", "firstName", e.target.value)
              }
              placeholder="First Name"
            />
          </div>
          <div className="form-group-checkout">
            <label htmlFor="lastName" className="required">
              Last Name
            </label>
            <input
              id="lastName"
              type="text"
              className="form-control"
              value={
                userDetails ? userDetails.last_name : customerInfo.lastName
              }
              // onBlur={()=>{handleBlur("lastName", event.target)}}
              onChange={(e) =>
                handleChange("customerInfo", "lastName", e.target.value)
              }
              placeholder="Last Name"
            />
          </div>
        </div>

        <div className="form-group-checkout">
          <label htmlFor="email" className="required">
            Email
          </label>
          <input
            id="email"
            type="email"
            className="form-control"
            value={userDetails ? userDetails.email : customerInfo.email}
            onBlur={(e) => {
              handleBlur("email", e.target.value);
            }}
            onChange={(e) =>
              handleChange("customerInfo", "email", e.target.value)
            }
            placeholder="Email"
          />
        </div>

        <div className="form-group-checkout">
          <label htmlFor="phone" className="required">
            Phone
          </label>
          <input
            id="phone"
            type="text"
            className="form-control"
            value={customerInfo.phone}
            onChange={(e) =>
              handleChange("customerInfo", "phone", e.target.value)
            }
            placeholder="Phone"
          />
        </div>

        {!isLoggedIn ? (
          <div className="checkbox-group hidden">
            <input
              id="optInPromotionalEmails"
              type="checkbox"
              className="form-checkbox"
              checked={checkoutOptions.optInPromotionalEmails}
              // onBlur={()=>{handleBlur("checkbox", event.target)}}
              onChange={(e) =>
                handleChange(
                  "checkoutOptions",
                  "optInPromotionalEmails",
                  e.target.checked,
                )
              }
            />
            <label htmlFor="optInPromotionalEmails">
              Sign me up for exclusive updates and special offers!
            </label>
          </div>
        ) : (
          <></>
        )}
        {/* <div className="checkbox-group">
                    <input
                        id="optInTextMessages"
                        type="checkbox"
                        className="form-checkbox"
                        checked={checkoutOptions.optInTextMessages}
                        onChange={(e) => handleChange('checkoutOptions', 'optInTextMessages', e.target.checked)}
                    />
                    <label htmlFor="optInTextMessages">Send me text message for delivery updates</label>
                </div> */}

        {!userDetails ? (
          <p className="guest-checkout-prompt">
            You are currently checking out as a guest. Log in or{" "}
            <Link to="/customer/register" style={{ color: "#173050" }}>
              register
            </Link>{" "}
            for this purchase.{" "}
          </p>
        ) : (
          <p className="guest-checkout-prompt">
            {" "}
            Welcome {userDetails.first_name}{" "}
          </p>
        )}

        {/* <button className="checkout-login-button" type="submit">Login</button> */}
      </fieldset>
    </div>
  );
};

export default CustomerInfo;

// <div className="form-group-checkout">
//     <label htmlFor="email">Phone</label>
//     <input
//         id="phone"
//         type="phone"
//         className="form-control"
//         value={userDetails ? userDetails.phone : customerInfo.phone}
//         onChange={(e) => handleChange('customerInfo', 'phone', e.target.value)}
//         placeholder="Phone"
//     />
// </div>
