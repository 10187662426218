import React, { useState, useEffect } from "react";
import "./css/Stores.css";
import SignUpButton from "./components/SignUpButtom";
import PopUp from "./components/PopUp";
import axios from "axios";

const Stores = () => {
  const [storeList, setStores] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = async () => {
    setIsloading(true);

    try {
      const storeUrl =
        "https://thefishermansmarket.ca/wp-json/fm_manager/v1/get_store_locations";
      const res = await axios.get(storeUrl, {});
      const retrievedStores = res.data;
      if (retrievedStores.length !== 0) {
        setStores(retrievedStores);
      }
      setIsloading(false);
    } catch (error) {
      console.error("Error fetching stores:", error);
      setIsloading(false);
    }
  };

  var ourStores = [];
  var satelliteLocations = [];
  {
    storeList.map((s, index) => {
      s.hours = [];
      s.address = s.address ? s.address : "";
      s.address2 = s.address2 ? s.address2 : "";
      s.city = s.city ? s.city : "";
      s.state = s.state ? s.state : "";
      s.zip = s.zip ? s.zip : "";
      s.country = s.country ? s.country : "";
      s.lat = s.lat ? s.lat : "";
      s.long = s.long ? s.long : "";
      s.phone = s.phone ? s.phone : "";
      s.email = s.email ? s.email : "";
      s.closed = s.closed_notes ? s.closed_notes : "";
      s.notes = s.notes ? s.notes : "";
      s.categories = s.categories ? s.categories : [];
      s.imageUrl = s.thumbnail ? s.thumbnail : "";
      s.mapLink = s.map_link ? s.map_link : null;
      if (!s.mapLink) {
        s.mapLink = new URL(
          "https://www.google.com/maps/place/" +
            s.name.replace(/ /g, "+") +
            "/@" +
            s.lat +
            "," +
            s.long +
            ",17z/",
        );
      }

      if (s.days1 && s.hours1) {
        s.hours.push({
          day: s.days1,
          time: s.hours1,
        });
      }

      if (s.days2 && s.hours2) {
        s.hours.push({
          day: s.days2,
          time: s.hours2,
        });
      }

      if (s.days3 && s.hours3) {
        s.hours.push({
          day: s.days3,
          time: s.hours3,
        });
      }

      if (s.categories.includes("Our Stores")) {
        ourStores.push(s);
      }

      if (s.categories.includes("Satellite Locations")) {
        satelliteLocations.push(s);
      }
    });
  }

  return (
    <div className="stores-list">
      <div className="store-list-content">
        <div className="list-content-preface">
          <h2 className="page-title">Store Locations</h2>
          <p className="page-des">
            We meticulously create a coastal atmosphere at each Fisherman's
            Market, making it feel like you're meeting the boat at the dock. Our
            expert staff, many of whom are fishers, help you choose the freshest
            catch for your meal.
          </p>
          <p className="page-des">
            Each of our British Columbia stores features expert fishmongers who
            offer personalized help in selecting sustainable, high-quality,
            fresh seafood at convenient locations.
          </p>
          <p className="page-des">Your Trusted leader in BC Seafood</p>
        </div>

        <div className="store-list">
          <div class="store-category-title">
            <h3>Our Stores</h3>
          </div>
          {ourStores.map((store, index) => {
            return (
              <div className="store-list-item" key={index}>
                <div>
                  <div
                    className="store-photo"
                    style={{
                      backgroundImage: `url(${store.imageUrl})`,
                    }}
                  ></div>
                  <a
                    href={store.mapLink}
                    target="_blank"
                    rel="noreferrer"
                    title="Google Map"
                    className="store-navi"
                  >
                    Go There
                  </a>
                </div>
                <div>
                  <h3>{store.name}</h3>

                  {store.hours.map((hour, indexY) => {
                    return (
                      <div className="store-open" key={indexY}>
                        <span>{hour.day} </span>
                        <span>{hour.time}</span>
                      </div>
                    );
                  })}
                  <p>{store.closed}</p>

                  <p>
                    <a
                      href={store.mapLink}
                      target="_blank"
                      rel="noreferrer"
                      title="Google Map"
                    >
                      {store.address}
                      <br /> {store.city}, {store.province} {store.zip}
                    </a>
                  </p>
                  {store.note && <p>{store.note}</p>}
                  <p>{store.phone}</p>
                  <p>
                    <a href={`mailto:${store.email}`}>{store.email}</a>
                  </p>
                </div>
              </div>
            );
          })}
          <div class="store-category-title grid-padding">
            <h3>Satellite Locations</h3>
          </div>
          {satelliteLocations.map((store, index) => {
            return (
              <div className="store-list-item" key={index}>
                <div>
                  <div
                    className="store-photo"
                    style={{
                      backgroundImage: `url(${store.imageUrl})`,
                    }}
                  ></div>
                  <a
                    href={store.mapLink}
                    target="_blank"
                    rel="noreferrer"
                    title="Google Map"
                    className="store-navi"
                  >
                    Go There
                  </a>
                </div>
                <div>
                  <h3>{store.name}</h3>

                  {store.hours.map((hour, indexY) => {
                    return (
                      <div className="store-open" key={indexY}>
                        <span>{hour.day} </span>
                        <span>{hour.time}</span>
                      </div>
                    );
                  })}
                  <p>{store.closed}</p>

                  <p>
                    <a
                      href={store.mapLink}
                      target="_blank"
                      rel="noreferrer"
                      title="Google Map"
                    >
                      {store.address}
                      <br /> {store.city}, {store.province} {store.zip}
                    </a>
                  </p>
                  {store.note && <p>{store.note}</p>}
                  <p>{store.phone}</p>
                  <p>
                    <a href={`mailto:${store.email}`}>{store.email}</a>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <SignUpButton />
      <PopUp />
    </div>
  );
};

export default Stores;
