// PaymentForm.js
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
  CardElement,
} from "@stripe/react-stripe-js";
import axios from "axios";

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [email, setEmail] = useState("");
  const [cardholderName, setCardholderName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");
  //   if (stripe) {
  //     const pr = stripe.paymentRequest({
  //       country: 'US',
  //       currency: 'usd',
  //       total: {
  //         label: 'Total',
  //         amount,
  //       },
  //       requestPayerName: true,
  //       requestPayerEmail: true,
  //     });

  //     pr.canMakePayment().then((result) => {
  //       if (result) {
  //         setPaymentRequest(pr);
  //       }
  //     });

  //     pr.on('paymentmethod', async (ev) => {
  //       setIsProcessing(true);

  //       const { data: clientSecret } = await axios.post('http://localhost:3000/create-payment-intent', {
  //         email: ev.payerEmail,
  //         cardholderName: ev.payerName,
  //         address,
  //         city,
  //         state,
  //         zip,
  //         phone,
  //         amount,
  //         orderId,
  //       });

  //       const { error: confirmError } = await stripe.confirmCardPayment(clientSecret, {
  //         payment_method: ev.paymentMethod.id,
  //       });

  //       if (confirmError) {
  //         ev.complete('fail');
  //         setPaymentMessage(confirmError.message);
  //       } else {
  //         ev.complete('success');
  //         setPaymentMessage('Payment Successful!');
  //       }

  //       setIsProcessing(false);
  //     });
  //   }
  // }, [stripe]);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   setIsProcessing(true);

  //   const cardElement = elements.getElement(CardElement);

  //   try {
  //     const { data: clientSecret } = await axios.post('http://localhost:3000/create-payment-intent', {
  //       email,
  //       cardholderName,
  //       address,
  //       city,
  //       state,
  //       zip,
  //       phone,
  //       amount,
  //       orderId,
  //     });

  //     const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
  //       payment_method: {
  //         card: cardElement,
  //         billing_details: {
  //           name: cardholderName,
  //           email,
  //           address: {
  //             line1: address,
  //             city,
  //             state,
  //             postal_code: zip,
  //             country: 'US',
  //           },
  //           phone,
  //         },
  //       },
  //     });

  //     if (error) {
  //       setPaymentMessage(error.message);
  //     } else if (paymentIntent.status === 'succeeded') {
  //       setPaymentMessage('Payment Successful!');
  //     }
  //   } catch (error) {
  //     setPaymentMessage(error.message);
  //   }

  //   setIsProcessing(false);
  // };

  return (
    <div>
      <div>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="address">Address</label>
        <input
          id="address"
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="city">City</label>
        <input
          id="city"
          type="text"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="state">State</label>
        <input
          id="state"
          type="text"
          value={state}
          onChange={(e) => setState(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="zip">ZIP</label>
        <input
          id="zip"
          type="text"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="phone">Phone</label>
        <input
          id="phone"
          type="text"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="cardholderName">Cardholder Name</label>
        <input
          id="cardholderName"
          type="text"
          value={cardholderName}
          onChange={(e) => setCardholderName(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="card-element">Credit or Debit Card</label>
        <CardElement id="card-element" />
      </div>
      {paymentMessage && <div>{paymentMessage}</div>}
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
    </div>
  );
};

export default PaymentForm;

// import React, { useState, useEffect } from 'react'
// import BillingForm  from './BillingForm'

// import { loadStripe } from '@stripe/stripe-js';
// import { CardElement, useStripe, Elements, PaymentElement } from '@stripe/react-stripe-js';

// const stripePromise = loadStripe('pk_live_510wXf6AtUiGVvMtLB5rrGzvYo8pJ70nThLCNSZGFOeS0en2eMxfURGqrNNhlKOVFfYXlMBSFr6jEQriTnp8xloPQ00wxYUYgBc');

// const StripeBilling = () => {
//     const [clientSecret, setClientSecret] = useState("");

//     useEffect(() => {
//         // Create PaymentIntent as soon as the page loads
//         fetch("/chk/create-payment-intent", {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
//         })
//             .then((res) => res.json())
//             .then((data) => setClientSecret(data.clientSecret));
//     }, []);

//     const appearance = {
//         theme: 'stripe',
//     };
//     const options = {
//         clientSecret,
//         appearance,
//     };

//     return (
//         <div className="App">
//             {clientSecret && (
//                 <Elements options={options} stripe={stripePromise}>
//                     <BillingForm />
//                 </Elements>
//             )}
//         </div>
//     );
// }

// export default StripeBilling;
