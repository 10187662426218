import React, {
  useReducer,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";
import axios from "axios"; // Ensure axios is installed
// create context
import { useMutation } from "@tanstack/react-query";
import { useLocationContext } from "./LocationContext";
import { get } from "react-hook-form";
const CheckoutContext = createContext();

function reducer(state, action) {
  switch (action.type) {
    case "SET_STORE_PAYMENT":
      return {
        ...state,
        storePayment: action.payload,
        onlinePayment: !action.payload,
      };
    case "SET_ONLINE_PAYMENT":
      return {
        ...state,
        onlinePayment: action.payload,
        storePayment: !action.payload,
      };
    case "SET_STORE_PICKUP":
      return {
        ...state,
        storePickup: action.payload,
        storeDelivery: !action.payload,
      };
    case "SET_STORE_DELIVERY":
      return {
        ...state,
        storeDelivery: action.payload,
        storePickup: !action.payload,
      };
    case "SET_SHIPPING_COST":
      return { ...state, shippingCost: action.payload };
    case "SET_PICKUP_LOCATION":
      console.log("SET_PICKUP_LOCATION", action.payload);
      const location = findLocationByName(
        action.payload.pickupLocations,
        action.payload.name,
      );
      return { ...state, pickupLocation: location };
    case "SET_ORDER_NOTES":
      console.log("SET_ORDER_NOTES", action.payload);
      return { ...state, orderNotes: action.payload };
    case "SET_OPT_IN_PROMOTIONAL_EMAIL":
      console.log("SET_OPT_IN_PROMOTIONAL_EMAIL", action.payload);
      return { ...state, optInPromotionalEmails: action.payload };
    default:
      throw new Error();
  }
}

function findLocationByName(locations, name) {
  return locations.find((location) => location.name === name);
}

const initialCheckoutState = {
  isGuestCheckout: true,
  createAccountPostPurchase: true,
  optInPromotionalEmails: true,
  optInTextMessages: false,
  storePickup: true,
  storeDelivery: false,
  storePayment: true,
  onlinePayment: false,
  shippingCost: 0.0,
  pickupLocation: {},
  orderNotes: "",
};

// customize hook for accessing shopping cart context in components
export const useCheckout = () => useContext(CheckoutContext);

// provider component
export const CheckoutProvider = ({ children }) => {
  const [checkoutOptionsNew, dispatchCheckoutOptions] = useReducer(
    reducer,
    initialCheckoutState,
  );

  const apiUrl = "https://thefishermansmarket.ca/wp-json/";
  const consumerKey = process.env.REACT_APP_WC_API_KEY;
  const consumerSecret = process.env.REACT_APP_WC_API_SECRET;

  const axiosPaymentGatewayInstance = axios.create({
    baseURL: apiUrl,
    auth: {
      username: consumerKey,
      password: consumerSecret,
    },
    params: {
      _fields: "id,title,method_title,description,enabled", // Specify only these fields in the response
    },
  });

  const axiosPickupLocationInstance = axios.create({
    baseURL: apiUrl,
    params: {
      // _fields: 'id,title,method_title,description'
    },
  });

  const initialState = {
    customerInfo: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    pickupLocationAddress: {
      name: "", // This can be the same as billing or different for pickup orders
      address: {
        address_1: "",
        city: "",
        state: "British Columbia",
        postalCode: "",
        country: "Canada",
      },
      details: "",
      enabled: "",
    },
    shippingAddress: {
      name: "", // This can be the same as billing or different for pickup orders
      streetAddress: "",
      city: "",
      state: "British Columbia",
      postalCode: "",
      country: "Canada",
    },
    billingAddress: {
      name: "", // This can be derived from customerInfo if the same
      streetAddress: "",
      city: "",
      state: "British Columbia",
      postalCode: "",
      country: "Canada",
    },
    paymentInfo: {
      paymentType: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      billingName: "", // Optional: can use billingAddress.name
    },
    discountCodeInfo: {
      code: "",
      discountAmount: 0,
      validationStatus: false,
    },
    checkoutOptions: {
      isGuestCheckout: true,
      createAccountPostPurchase: true,
      optInPromotionalEmails: true,
      optInTextMessages: false,
      storePickup: true,
      storeDelivery: false,
      storePayment: true,
      onlinePayment: false,
    },
  };

  const [pickupLocation, setPickupLocation] = useState({});

  const getInitialState = () => {
    const savedState = localStorage.getItem("checkout-state");
    return savedState ? JSON.parse(savedState) : initialState;
  };

  const shippingCost = useMutation({
    mutationFn: async (data) => {
      console.log("Fetching Shipping Cost");
      // console.log("data from shipingcost", data)
      const response = await fetch(
        "https://thefishermansmarket.ca/wp-json/shipping-cost/calculate-shipping/",
        {
          method: "POST",
          // auth: {
          //   username: consumerKey,
          //   password: consumerSecret,
          // },
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            city: data.city,
            province: data.province,
            cart_total: data.cartTotal,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return response.json();
    },
    onSuccess: async (shippingCost) => {
      // console.log("Fetched Shipping Cost");
      // console.log(shippingCost);
      // Dispatch an action to the reducer to update the state with the shipping cost
      dispatchCheckoutOptions({
        type: "SET_SHIPPING_COST",
        payload: shippingCost.shipping_cost,
      });
    },
    onError: async (error) => {
      console.log("Failed to Fetch Shipping Cost");
      console.log(error);
    },
  });

  const getShippingCost = (province) => {
    // const savedUserShippingInfo = JSON.parse(localStorage.getItem("userDetails")).shipping;
    // shippingCost.mutate({ province: province, city: savedUserShippingInfo.city, cartTotal: this.calculateTotalCartPrice() })
  };

  const { selectedLocation, setSelectedLocation } = useLocationContext();

  const [pickupLocations, setPickupLocations] = useState([]);

  const getPickupLocations = useMutation({
    mutationFn: () => {
      return axiosPickupLocationInstance.get(
        `feature-api/v1/option/pickup-location/${selectedLocation}`,
      );
    },
    onSuccess: async (response) => {
      console.log("Fetched Pickup Locations");
      const filteredData = response.data.filter((item) => {
        return item.enabled === true;
      });
      console.log(filteredData);
      localStorage.setItem("pickupLocations", JSON.stringify(filteredData));
      setPickupLocations(response.data);
      setPickupLocation(response.data[0]);
    },
    onError: async (error) => {
      console.log("Failed to Fetch Pickup Locations");
      console.log(error);
    },
  });

  const getPaymentGateways = useMutation({
    mutationFn: () => {
      console.log("Fetching Payment Gateways");
      return axiosPaymentGatewayInstance.get("wc/v3/payment_gateways");
    },
    onSuccess: async (response) => {
      console.log("Fetched Payment Gateways");
      localStorage.setItem("paymentGateways", JSON.stringify(response.data));
      setPaymentGateways(response.data);
    },
    onError: async (error) => {
      console.log("Failed to Fetch Payment Gateways");
      console.log(error);
    },
  });

  const [paymentGateways, setPaymentGateways] = useState(() => {
    getPaymentGateways.mutate();
    if (getPaymentGateways.isPending) {
      return [{ name: "Fetching Data" }];
    } else {
      console.log("SetGateways");
    }
  });

  const [state, setState] = useState(getInitialState);

  useEffect(() => {
    localStorage.setItem("checkout-state", JSON.stringify(state));
    // console.log("Changing Checkout State")
  }, [state]);

  useEffect(() => {
    if (selectedLocation !== "") {
      getPickupLocations.mutate();
    }
    // console.log("Changing Checkout State")
  }, [selectedLocation]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      checkoutOptions: {
        ...prevState.checkoutOptions,
        ...checkoutOptionsNew, // Merge new options
      },
    }));
    console.log("Changing checkoutOptionsNew in localstorage");
  }, [checkoutOptionsNew]);

  const prepareRequestData = (cartItems) => {
    console.log("prepareRequestData: ");
    const customer = state.customerInfo;
    const shipping = state.shippingAddress;
    // check the checkoutoptions
    const data = {
      // Needs to be fetched from Woo to Tell what gateways are available. This might determine the form state
      // What payment methods do we have.
      payment_method: "bacs",
      payment_method_title: "Direct Bank Transfer",

      set_paid: false,
      billing: {
        first_name: customer.firstName,
        last_name: customer.lastName,
        address_1: shipping.streetAddress,
        address_2: shipping.apartment || "",
        city: shipping.city,
        // state: shipping.state,
        state: "British Columbia",
        postcode: shipping.postalCode,
        // country: shipping.country,
        country: "Canada",
        email: customer.email,
        phone: customer.phone,
      },
      shipping: {
        first_name: customer.firstName,
        last_name: customer.lastName,
        address_1: shipping.streetAddress,
        address_2: shipping.apartment || "",
        city: shipping.city,
        // state: shipping.state,
        state: "British Columbia",
        postcode: shipping.postalCode,
        country: "Canada",
        phone: customer.phone,
        // country: shipping.country
      },
      line_items: cartItems,
      // shipping_lines: shippingLines
    };

    return data;
  };

  const placeOrder = (cartItems) => {
    const items = getLineItemsForWoo(cartItems);
    console.log("cartItems in placeOrder: ", cartItems);
    var wooOrder = prepareRequestData(items);
  };

  const toggleDeliveryOption = () => {
    console.log("Toggle");
    setState((prevState) => ({
      ...prevState.checkoutOptions,
      storeDelivery: !prevState.storeDelivery,
      storePickup: !prevState.storePickup,
    }));
  };

  const updateCustomerInfo = (customerInfo) => {
    setState({ ...state, customerInfo });
  };

  const getCustomerInfo = () => {
    return state.customerInfo;
  };

  const getShippingAddress = () => {
    return state.shippingAddress;
  };

  const updateBillingInfo = (billingInfo) => {
    localStorage.setItem("billingInfo", JSON.stringify(billingInfo));
    setState({ ...state, billingInfo });
  };

  const updateShippingInfo = (shippingInfo) => {
    localStorage.setItem("shippingInfo", JSON.stringify(shippingInfo));
    setState({ ...state, shippingInfo });
  };

  const updatePickupLocationAddress = (pickupLocationAddress) => {
    // Make sure to fill out all the details for the pickup location.
    localStorage.setItem(
      "pickupLocationAddress",
      JSON.stringify(pickupLocationAddress),
    );
    setState({ ...state, pickupLocationAddress });
  };

  const toggleGuestOption = (option, value) => {
    setState({
      ...state,
      guestOptions: { ...state.guestOptions, [option]: value },
    });
  };

  const toggleCheckoutOption = (value) => {
    console.log("toggleCheckoutOption: ", value);
    let field = value;
    setState((prevState) => {
      return {
        ...prevState,
        checkoutOptions: {
          ...prevState.checkoutOptions,
          field: !value,
        },
      };
    });
  };

  const getCheckoutOptions = () => {
    return state.checkoutOptions;
  };

  const setCheckoutOption = (key, newValue) => {
    console.log("setCheckoutOption: ", key, newValue);
    setState((prevState) => ({
      ...prevState,
      checkoutOptions: {
        ...prevState.checkoutOptions,
        [key]: newValue,
      },
    }));
  };

  const updatePaymentInfo = (paymentInfo) => {
    setState({ ...state, paymentInfo });
  };

  const updateDiscountCodeInfo = (discountCodeInfo) => {
    setState({ ...state, discountCodeInfo });
  };

  const validateDiscountCode = (code) => {
    // This function might interact with your backend or a promotions service
    // to validate the discount code and update the discountCodeInfo accordingly
    // Placeholder for validation logic
    // After validation, update the state:
    // updateDiscountCodeInfo({ code, discountAmount: calculatedAmount, validationStatus: true/false });
  };

  const getLineItemsForWoo = (items) => {
    return items.map((item) => ({
      product_id: item.id,
      quantity: item.quantity,
    }));
  };

  const contextValue = {
    ...state,
    updateCustomerInfo,
    updateBillingInfo,
    updateShippingInfo,
    updatePickupLocationAddress,
    toggleGuestOption,
    toggleCheckoutOption,
    updatePaymentInfo,
    updateDiscountCodeInfo,
    validateDiscountCode,
    toggleDeliveryOption,
    placeOrder,
    getCustomerInfo,
    getShippingAddress,
    pickupLocations,
    paymentGateways,
    getLineItemsForWoo,
    getCheckoutOptions,
    setCheckoutOption,
    checkoutOptionsNew,
    dispatchCheckoutOptions,
    shippingCost,
    pickupLocation,
  };

  // return cart data and function to all components
  return (
    <CheckoutContext.Provider value={contextValue}>
      {children}
    </CheckoutContext.Provider>
  );
};
