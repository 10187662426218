import Skeleton from "react-loading-skeleton";

const CardSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <div className="card-skeleton" key={i}>
        <div className="card-skeleton-image">
          <Skeleton height={200} />
        </div>
        <div className="card-content">
          <Skeleton count={3} />
        </div>
        <div className="card-skeleton-footer">
          <Skeleton height={40} />
        </div>
      </div>
    ));
};
export default CardSkeleton;
