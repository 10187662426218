import React from "react";
import PriceListBannerImage from "../images/pricelist.webp";
import { Link } from "react-router-dom";
import "../css/PriceListBanner.css";

const PriceListBanner = ({ text1, text2, text3, buttonText }) => {
  return (
    <div
      className="price-list-banner"
      style={{ backgroundImage: `url(${PriceListBannerImage})` }}
    >
      <div className="price-list-banner-wrapper">
        <div className="price-list-banner-content">
          <div className="price-list-banner-text1">{text1}</div>
          <div className="price-list-banner-text2">{text2}</div>
          <div className="price-list-banner-text3">{text3}</div>

          <div className="price-list-banner-button-content">
            <Link to="/our-market" className="price-list-banner-button">
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceListBanner;
