const termsData = {
  title: "Terms and Conditions for FM Seafood / The Fisherman\'s Market",
  body: [
    {
      id: 1,
      subtitle: "Introduction",
      body: {
        type: "p",
        content: [
          'Welcome to thefishermansmarket.ca, the online presence of FM Seafood, also known as Fisherman\'s Market ("we," "us," or "our"). We are dedicated to providing you with the highest quality seafood products. Access to and use of our services, including our website and any related services, are governed by these Terms and Conditions ("Terms").',
        ],
      },
    },
    {
      id: 2,
      subtitle: "Acceptance of Terms",
      body: {
        type: "p",
        content: [
          "By accessing our site, registering an account, or completing a purchase, you signify your unconditional agreement to, and acceptance of, these Terms and any other applicable laws or regulations. If you do not agree with these Terms, you should not use our services.",
        ],
      },
    },
    {
      id: 3,
      subtitle: "User Registration and Newsletter Subscription",
      body: {
        type: "ul",
        content: [
          "Account Creation: To access certain features of our site, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
          "Newsletter Subscription: By registering an account with us, you agree to subscribe to newsletters, marketing or promotional materials, and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.",
        ],
      },
    },
    {
      id: 4,
      subtitle: "Shipping Policy",
      body: {
        type: "ol",
        content: [
          "Delivery Scope: Our delivery services are available throughout the lower mainland of Vancouver and the Okanagan region. Specifically, this includes areas from Kelowna to Penticton and from Kelowna to Salmon Arm.",
          "Shipping Fees:<ul><li>Orders valued at $250 or more qualify for free shipping.</li><li>For orders under $250, the following shipping fees apply:</li><li>Lower mainland of Vancouver: A shipping fee of $30.</li><li>Okanagan region: A shipping fee of $20.</li></ul>",
          "Delivery Provider: Deliveries are conducted by Bobby Cool Courier Service, ensuring timely and reliable service to your doorstep.",
          "Estimated Delivery Time:<ul><li>Orders are typically processed and dispatched within 1 business day from the time they are fully prepared.</li><li>Customers will be notified of any delays or issues regarding their order as soon as possible.</li></ul>",
          "Please note that by placing an order, you agree to the terms outlined in our Delivery Terms and Conditions. We strive to ensure that your order reaches you in a timely manner and appreciate your understanding and cooperation.",
        ],
      },
    },
    {
      id: 5,
      subtitle: "Refund and Return Policy",
      body: {
        type: "p",
        content: [
          "Our products come with a full guarantee of quality. If any item does not meet our high standards, please contact the manager at your local store for assistance in resolving any issues. We are dedicated to ensuring your complete satisfaction with every purchase.",
        ],
      },
    },
    {
      id: 6,
      subtitle: "Order Preparation and Fulfilment",
      body: {
        type: "ul",
        content: [
          "Preparation Time: Due to our reliance on fresh supply chains and the specific requirements of preparing seafood, order preparation can take 2-3 business days or longer. We appreciate your patience and understanding.",
          "Order Confirmation: You will receive a confirmation email after placing your order. This email will serve as your receipt and include your order details.",
        ],
      },
    },
    {
      id: 7,
      subtitle: "Pricing and Payment",
      body: {
        type: "ul",
        content: [
          "Reference Pricing: The prices on our website are for reference only, as the actual price may vary based on the weight of the product and market rates. The final price will be determined at the time of order fulfilment.",
          "Payment Methods: We accept various payment methods, including credit cards and online payment platforms. Payment must be completed at the time of order pickup unless otherwise specified.",
        ],
      },
    },
    {
      id: 8,
      subtitle: "Modifications to Services and Prices",
      body: {
        type: "p",
        content: [
          "We reserve the right at any time to modify or discontinue, temporarily or permanently, the service (or any part thereof) with or without notice. Prices of all products are subject to change without notice. We shall not be liable to you or to any third party for any modification, price change, suspension, or discontinuance of the service.",
        ],
      },
    },
    {
      id: 9,
      subtitle: "Intellectual Property Rights",
      body: {
        type: "p",
        content: [
          "All materials on our site, including, without limitation, the logo, design, text, graphics, and other files, and the selection and arrangement thereof are our proprietary property or the proprietary property of our licensors or users, protected by copyright laws.",
        ],
      },
    },
    {
      id: 10,
      subtitle: "Limitation of Liability",
      body: {
        type: "p",
        content: [
          "FM Seafood/The Fisherman's Market shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services, including but not limited to damages for loss of profits, data, or other intangibles, even if we have been advised of the possibility of such damages.",
        ],
      },
    },
    {
      id: 11,
      subtitle: "Privacy Policy",
      body: {
        type: "p",
        content: [
          "Your privacy is important to us. Our Privacy Policy, which is part of these Terms, describes how we collect, use, and disclose information that pertains to your privacy. By agreeing to these Terms, you agree to be bound by our Privacy Policy.",
        ],
      },
    },
    {
      id: 12,
      subtitle: "Changes to Terms",
      body: {
        type: "p",
        content: [
          "We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms.",
        ],
      },
    },
    {
      id: 13,
      subtitle: "Additional Legal Disclaimers",
      body: {
        type: "ul",
        content: [
          "<b>Health and Safety Disclaimer:</b> We take great care in sourcing and preparing our seafood products. However, consumers are responsible for ensuring that seafood is cooked and prepared properly before consumption. We cannot be held responsible for any health issues resulting from the improper handling or cooking of seafood purchased from our site.",
          "<b>Accuracy of Information:</b> We endeavour to ensure that all information on our site is accurate, complete, and current. However, there may be instances when information about a product contains inaccuracies, omissions, or is outdated. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update information at any time without prior notice.",
        ],
      },
    },
    {
      id: 14,
      subtitle: "Governing Law",
      body: {
        type: "p",
        content: [
          "These Terms shall be governed and construed in accordance with the laws of Canada, without regard to its conflict of law provisions.",
        ],
      },
    },
    {
      id: 15,
      subtitle: "Contact Information",
      body: {
        type: "p",
        content: [
          "For any questions or concerns regarding these Terms, please contact us at info@thefishermansmarket.ca.",
        ],
      },
    },
  ],
};
export default termsData;
