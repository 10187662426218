import React, { useState, useEffect, useRef, useCallback } from "react";
import { axiosProductsInstance } from "../dataFetch/axios.main";
import "../css/Navbar.css";
import logoImage from "../images/FMS_horizontal-logo-RGB-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faCartShopping,
  faUser,
  faXmark,
  faBars,
  faSignOutAlt,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../context/CartContext";
import { useUser } from "../context/UserContext";
import SearchResult from "./SearchResult";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

import NavBarLink from "./NavBarLink";
import CountdownBanner from "./CountdownBanner";
import StoreSelector from "./StoreSelector";
import { useLocationContext } from "../context/LocationContext";
import storeData from "../data/storeData";
import toast, { Toaster } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";

const Navbar = () => {
  const navigate = useNavigate();

  const {
    user,
    userDetails,
    getCustomerDetails,
    validateUser,
    isLoggedIn,
    signOut,
  } = useUser();
  const { getTotalItems } = useCart();
  // State to track if the search input should be shown
  const [showSearch, setShowSearch] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  // Function to toggle the search input visibility
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedStore, setSelectedStore] = useState({
    name: "",
    hours: [{ time: "" }],
  });
  const {
    selectedLocation,
    setSelectedLocation,
    showSelector,
    setShowSelector,
  } = useLocationContext();

  const alreadyRedirected =
    JSON.parse(localStorage.getItem("alreadyRedirected")) || false;

  useEffect(() => {
    switch (selectedLocation) {
      case "ke":
        setSelectedStore(storeData.find((store) => store.name === "Kelowna"));
        break;
      case "ne":
        setSelectedStore(storeData.find((store) => store.name === "Nelson"));
        break;
      case "ka":
        setSelectedStore(storeData.find((store) => store.name === "Kamloops"));
        break;
      case "gi":
        setSelectedStore(storeData.find((store) => store.name === "Gibsons"));
        break;
      case "wv":
        setSelectedStore(
          storeData.find((store) => store.name === "West Vancouver"),
        );
        break;
      case "bu":
        setSelectedStore(storeData.find((store) => store.name === "Burnaby"));
        break;
      default:
        setSelectedStore(storeData.find((store) => store.name === "Kelowna"));
    }
  }, [selectedLocation]);

  const fetchData = useCallback(
    (searchQuery) => {
      // if (!selectedLocation) return;
      const paramsObject = alreadyRedirected
        ? { blog_id: selectedLocation, search: searchQuery, size: 10 }
        : { search: searchQuery, size: 10 };
      setLoading(true);
      console.log("Current store in search:", selectedLocation);
      console.log(searchQuery);
      axiosProductsInstance.get("", { params: paramsObject }).then((res) => {
        setSearchResults(res.data);
        setLoading(false);
      });
    },
    [selectedLocation],
  );

  const searchBarRef = useRef(null);

  const toggleOff = () => {
    setShowSearch(false);
  };

  const handleDataFromCountdown = (data) => {
    return;
  };

  const debounce = (func, delay) => {
    let timeoutId;

    return function (...args) {
      const context = this;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const debounceSearch = useCallback(
    debounce((query) => {
      if (query.trim() === "") {
        setSearchResults([]);
        setLoading(false);
        return;
      }

      fetchData(query);
    }, 300),
    [fetchData, selectedLocation],
  );

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    e.target.value === "" ? setShowSearch(false) : setShowSearch(true);
    debounceSearch(e.target.value);
  };

  const handleSearchBarClose = () => {
    setSearchQuery("");
    setShowSearch(false);
    debounceSearch("");
  };

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      setShowSearch(false);
      setSearchQuery("");
      debounceSearch("");
      navigate(`/search-results/${searchQuery}`);
    }
  };

  // State to hold whether the page has been scrolled down
  const [scrolled, setScrolled] = useState(false);
  // Effect hook to add/remove scroll event listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        toggleOff();
        setMobileSearch(false);
      }
    };
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        ref={searchBarRef}
        className={scrolled ? "navbar scrolled" : "navbar"}
      >
        <div className="navbar-top-wrapper">
          <div className="navbar-store-locator-mobile">
            <div className="navbar-store-locator-mobile-wrapper">
              <button
                className="store-locator-button"
                onClick={(e) => setShowSelector(true)}
              >
                <span className="store-locator-icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </span>
                {alreadyRedirected ? (
                  <>
                    <span className="store-locator-selected">
                      {selectedStore.name}
                    </span>
                    <div className="store-locator-timing">
                      <span>{selectedStore.hours[0].time}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="store-locator-selected">
                      Select a store
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>

          <div className="navbar-top">
            <Link to="/" className="navbar-logo">
              <img src={logoImage} alt="Logo" className="logo" />
            </Link>

            <div className="navbar-store-locator">
              <button
                className="store-locator-button"
                onClick={(e) => setShowSelector(true)}
              >
                <span className="store-locator-icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </span>
                {alreadyRedirected ? (
                  <div className="store-locator-name">
                    <span className="store-locator-selected">
                      {selectedStore.name}
                    </span>
                    <div className="store-locator-timing">
                      <span>{selectedStore.hours[0].time}</span>
                    </div>
                  </div>
                ) : (
                  <div className="store-locator-name">
                    <span className="store-locator-selected">
                      Select a store
                    </span>
                  </div>
                )}
              </button>
            </div>

            <div className="search-bar">
              <div className="search-bar-input">
                <input
                  type="text"
                  placeholder="Search for products"
                  value={searchQuery}
                  onKeyDown={handleSearchEnter}
                  onChange={handleInputChange}
                />
                {showSearch &&
                  (loading ? (
                    <span className="search-bar-control loading">
                      <ClipLoader size={12} color="#173050" />
                    </span>
                  ) : (
                    <span
                      className="search-bar-control close"
                      onClick={handleSearchBarClose}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </span>
                  ))}
              </div>
              <Link
                to={`/search-results/${searchQuery}`}
                onClick={handleSearchBarClose}
                className="search-button"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Link>
            </div>

            <div className="navbar-buttons">
              <button onClick={() => setShowMenu(true)} className="menu-button">
                <FontAwesomeIcon icon={faBars} />
              </button>
              <button
                className="search-button-mobile"
                onClick={() => setMobileSearch(!mobileSearch)}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>

              <Link to="/cart" className="cart-button">
                <FontAwesomeIcon icon={faCartShopping} />
                {getTotalItems() > 0 && (
                  <span className="cart-count">{getTotalItems()}</span>
                )}
              </Link>
              {isLoggedIn ? (
                <>
                  <Link to="/customer/account" className="signin-button">
                    {getCustomerDetails.isPending || validateUser.isPending ? (
                      <Skeleton circle={true} height={20} width={20} />
                    ) : (
                      <img src={userDetails?.avatar_url} alt="Avatar" />
                    )}
                  </Link>
                  <Link className="signout-button" onClick={signOut}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </Link>
                </>
              ) : (
                <Link to="/customer/sign-in" className="signin-button">
                  <FontAwesomeIcon icon={faUser} />
                </Link>
              )}
            </div>
          </div>

          {mobileSearch && (
            <div className="search-bar-mobile">
              <div className="search-bar-mobile-input">
                <input
                  type="text"
                  placeholder="Search for products"
                  value={searchQuery}
                  onKeyDown={handleSearchEnter}
                  onChange={handleInputChange}
                />
                {showSearch &&
                  (loading ? (
                    <span className="search-bar-control loading">
                      <ClipLoader size={12} color="#173050" />
                    </span>
                  ) : (
                    <span
                      className="search-bar-control close"
                      onClick={handleSearchBarClose}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </span>
                  ))}
              </div>
              <Link
                to={`/search-results/${searchQuery}`}
                onClick={handleSearchBarClose}
                className="search-button"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Link>
            </div>
          )}

          <div className="navbar-bottom">
            {showMenu && (
              <div className="toggle-menu">
                <ul
                  className={
                    scrolled
                      ? "navbar-bottom-small scrolled"
                      : "navbar-bottom-small"
                  }
                >
                  <li>
                    <a onClick={() => setShowMenu(false)}>
                      <FontAwesomeIcon icon={faXmark} />
                    </a>
                  </li>
                  <li>
                    <Link to="/" onClick={() => setShowMenu(false)}>
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to="/shop" onClick={() => setShowMenu(false)}>
                      SHOP ONLINE
                    </Link>
                  </li>
                  <li>
                    <Link to="/our-market" onClick={() => setShowMenu(false)}>
                      OUR MARKET
                    </Link>
                  </li>
                  <li>
                    <Link to="/locations" onClick={() => setShowMenu(false)}>
                      LOCATIONS
                    </Link>
                  </li>
                  <li>
                    <Link to="/recipes" onClick={() => setShowMenu(false)}>
                      RECIPES
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" onClick={() => setShowMenu(false)}>
                      RESOURCES
                    </Link>
                  </li>
                  <li>
                    <Link to="/fundraiser" onClick={() => setShowMenu(false)}>
                      FUNDRAISERS
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" onClick={() => setShowMenu(false)}>
                      ABOUT US
                    </Link>
                  </li>
                </ul>
                <div
                  className="navbar-overlay"
                  onClick={() => setShowMenu(false)}
                ></div>
              </div>
            )}

            <ul className="navbar-bottom-normal">
              <li>
                <Link
                  to="/shop"
                  style={{ fontWeight: "bold", color: "#e9a343" }}
                >
                  SHOP ONLINE
                </Link>
              </li>
              <li>
                <Link to="/our-market">OUR MARKET</Link>
              </li>
              <li>
                <Link to="/locations">LOCATIONS</Link>
              </li>
              <li>
                <Link to="/recipes">RECIPES</Link>
              </li>
              <li>
                <Link to="/blog">RESOURCES</Link>
              </li>
              <li>
                <Link to="/fundraiser">FUNDRAISERS</Link>
              </li>
              <li>
                <Link to="/about-us">ABOUT US</Link>
              </li>
              <li>
                <Link to="/shipping">SHIPPING</Link>
              </li>
            </ul>
          </div>
        </div>
        {showSelector && <StoreSelector />}

        {showSearch && (
          <div
            className={
              searchResults.length === 0
                ? "search-results hide"
                : "search-results"
            }
          >
            <div className="search-results-wrapper">
              {searchResults.map((result) => (
                <Link
                  key={result.id}
                  to={`/product/${result.id}`}
                  onClick={(e) => setShowSearch(false)}
                  className="result-list"
                >
                  <div
                    className="result-image"
                    style={{ backgroundImage: `url(${result.thumbnail})` }}
                  />
                  <div className="result-info">
                    <h3>{result.name}</h3>
                    <div>
                      <span className="price">
                        ${alreadyRedirected ? result.blog_price : result.price}
                        <span className="price-uom">{result.unit}</span>
                      </span>
                      {alreadyRedirected ? (
                        <>
                          {result.blog_on_sale && (
                            <span className="regular-price">
                              ${result.blog_regular_price}
                              <span className="price-uom">{result.unit}</span>
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          {result.on_sale && (
                            <span className="regular-price">
                              ${result.regular_price}
                              <span className="price-uom">{result.unit}</span>
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
      <Toaster
        position="bottom-center"
        containerStyle={{
          bottom: 90,
          top: 135,
        }}
        reverseOrder={false}
      />
      <CountdownBanner
        smSize={false}
        isShowButton={true}
        dataToParent={handleDataFromCountdown}
      />
    </>
  );
};

export default Navbar;
