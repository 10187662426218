import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { useCart } from "../../context/CartContext";
import "../../css/SideBarProductItem.css";

const SideBarProductItem = ({ item }) => {
  const {
    removeItemFromCart,
    addItemToCart,
    removeAllItemsOfType,
    calculateItemTotalPrice,
  } = useCart();
  return (
    <div className="product-item-container">
      {/* Product Image */}
      <img className="product-item-image" src={item.thumbnail} />

      {/*  */}
      {/* <div className='product-details-container'> */}
      <div className="product-details-column">
        {/* Product Details */}
        <div className="product-item-name">{item.name}</div>
        <div className="cart-summary-container">
          <div className="product-item-quantity">
            Qty:&nbsp;<span style={{ fontWeight: "600" }}>{item.quantity}</span>
          </div>
          <div className="product-total-price">
            $&nbsp;<span>{calculateItemTotalPrice(item)}</span>
          </div>
        </div>
        {/* Product Quantity Selector */}
        {/* <div className='quantity-selector'>
                        <div className="add-product-icon-sidebar" onClick={() => removeItemFromCart(item.id)}><FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon></div>
                        <div className='font-bold'>{item.quantity}</div>
                        <div className="add-product-icon-sidebar" onClick={() => addItemToCart(item)}> <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon></div>
                    </div> */}
      </div>

      {/* <div className='cart-summary-container'> */}

      {/* Product Total Price */}
      {/* <div className='product-summary'>
                        <div className='font-medium text-base'>Total Price</div>
                        <div className='product-total-price'>$CA <span className='font-bold'>{calculateItemTotalPrice(item)}</span></div>
                    </div> */}

      {/* Remove Product From Cart */}
      {/* <div className='details-remove '>
                        <div onClick={() => removeAllItemsOfType(item.id)}>Remove</div>
                    </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};
export default SideBarProductItem;
