const storeData = [
  {
    id: 1,
    name: "Nelson",
    gm: "Dave Shaw",
    address: "577 Ward Street",
    city: "Nelson",
    province: "BC",
    zip: "V1L 1T1",
    phone: "(250) 505-5515",
    hours: [
      {
        day: "Mon - Sat",
        time: "10:30 AM – 5:30 PM",
      },
    ],
    closed: "Sun (Closed)",
    email: "nelson@fmseafoods.com",
    imageUrl: "./images/FMS_nelson.webp",
    mapLink:
      "https://www.google.com/maps/place/Fisherman's+Market/@49.4914545,-117.295339,17z/data=!3m1!4b1!4m5!3m4!1s0x537cb6a60d9993e9:0xc700205059d74e96!8m2!3d49.4914395!4d-117.2931857",
    placeId: "ChIJ6ZOZDaa2fFMRlk7XWVAgAMc",
    siteUrl: "https://ne.thefishermansmarket.ca",
    coordinate: {
      lat: 49.4914,
      lon: -117.2953,
    },
  },
  {
    id: 2,
    name: "Kelowna",
    gm: "Jessi Wall",
    address: "1951 Harvey Avenue",
    city: "Kelowna",
    province: "BC",
    zip: "V1Y 6G6",
    phone: "(250) 860-2541",
    hours: [
      {
        day: "Mon - Sat",
        time: "10:30 AM – 5:30 PM",
      },
      {
        day: "Sun",
        time: "11:00 AM – 4:00 PM",
      },
    ],
    closed: "",
    email: "kelowna@fmseafoods.com",
    imageUrl: "./images/FMS_kelowna.webp",
    mapLink:
      "https://www.google.com/maps/place/Fisherman's+Market+Kelowna/@49.8815157,-119.4543064,17z/data=!3m2!4b1!5s0x537d8b33d334d309:0xebb04e3b5dad1640!4m5!3m4!1s0x537d8cb420ec97c7:0xf203516972900654!8m2!3d49.8815157!4d-119.4521124",
    placeId: "ChIJx5fsILSMfVMRVAaQcmlRA_I",
    siteUrl: "https://ke.thefishermansmarket.ca",
    coordinate: {
      lat: 49.8815,
      lon: -119.4543,
    },
  },
  {
    id: 3,
    name: "Kamloops",
    gm: "Gina Fedora",
    address: "1180 Columbia St W C117",
    city: "Kamloops",
    province: "BC",
    zip: "V2C 6R6",
    phone: "(778) 471-3474",
    hours: [
      {
        day: "Mon – Sat",
        time: "10:00 AM – 5:30 PM",
      },
    ],
    closed: "Sun (Closed)",
    email: "kamloops@fmseafoods.com",
    imageUrl: "./images/FMS_Kamloops.webp",
    mapLink:
      "https://www.google.com/maps/place/Fisherman's+Market/@50.6661758,-120.3580672,17z/data=!3m1!4b1!4m5!3m4!1s0x537e2c5c1192291b:0xdf0c6e37582d73e4!8m2!3d50.6661758!4d-120.3558732",
    placeId: "ChIJGymSEVwsflMR5HMtWDduDN8",
    siteUrl: "https://ka.thefishermansmarket.ca",
    coordinate: {
      lat: 50.6661758,
      lon: -120.3580672,
    },
  },
  {
    id: 4,
    name: "West Vancouver",
    gm: "Katie Budd",
    address: "5349 Headland Drive",
    city: "West Vancouver",
    province: "BC",
    zip: "V7W 3C5",
    phone: "(604) 281-2000",
    hours: [
      {
        day: "Mon – Sat",
        time: "10:30 AM – 5:30 PM",
      },
    ],
    closed: "Sun (Closed)",
    email: "westvan@fmseafoods.com",
    imageUrl: "./images/FMS_West_Vancouver.webp",
    mapLink:
      "https://www.google.com/maps/place/Fisherman's+Market+West+Vancouver/@49.350964,-123.2564987,17z/data=!3m2!4b1!5s0x54866c13e819a3a3:0xd6685a6ccc5fdb1c!4m5!3m4!1s0x54866c3f10dba24d:0xf345faa06f1e98e2!8m2!3d49.350964!4d-123.2543047",
    placeId: "ChIJTaLbED9shlQR4pgeb6D6RfM",
    siteUrl: "https://wv.thefishermansmarket.ca",
    coordinate: {
      lat: 49.350964,
      lon: -123.2564987,
    },
  },
  {
    id: 5,
    name: "Gibsons",
    gm: "Emmas Tsao",
    address: "473 Gower Point Rd",
    city: "Gibsons",
    province: "BC",
    zip: "V0N 1V0",
    phone: "(604) 330-5705",
    hours: [
      {
        day: "Tue – Sun",
        time: "10:00 AM – 5:00 PM",
      },
    ],
    closed: "Mon (Closed)",
    email: "gibsons@fmseafoods.com",
    imageUrl: "./images/FMS_Gibsons.webp",
    mapLink:
      "https://www.google.com/maps/place/Fisherman's+Market+Gibsons/@49.398126,-123.5102,17z/data=!3m1!4b1!4m5!3m4!1s0x54863fce3e517b2d:0xf46cb63d7eceade4!8m2!3d49.3980741!4d-123.508128",
    placeId: "ChIJLXtRPs4_hlQR5K3Ofj22bPQ",
    siteUrl: "https://gi.thefishermansmarket.ca",
    coordinate: {
      lat: 49.3981,
      lon: -123.5102,
    },
  },
  {
    id: 6,
    name: "Burnaby",
    gm: "Tyrone Gordon",
    address: "4020 Hastings St",
    city: "Burnaby",
    province: "BC",
    zip: "V5C 2H9",
    phone: "(604) 568-0430",
    hours: [
      {
        day: "Mon – Sun",
        time: "10:30 AM – 5:30 PM",
      },
    ],
    closed: "",
    email: "regent@fmseafoods.com",
    imageUrl: "./images/FMS_Burnaby.webp",
    mapLink:
      "https://www.google.com/maps/place/Fisherman's+Market+(Regent+Fish+Market)/@49.2809523,-123.0177705,17z/data=!3m1!4b1!4m5!3m4!1s0x548670cf17138257:0x6f61ad50d4ddf97b!8m2!3d49.2809388!4d-123.0155739",
    placeId: "ChIJV4ITF89whlQRe_nd1FCtYW8",
    siteUrl: "https://bu.thefishermansmarket.ca",
    coordinate: {
      lat: 49.2809523,
      lon: -123.0177705,
    },
  },
  {
    id: 7,
    name: "New Denver",
    gm: "",
    address: "210 6th Ave",
    city: "New Denver",
    province: "BC",
    zip: "V0G 1S1",
    phone: "(250) 551-2368",
    hours: [
      {
        day: "Thu & Sat",
        time: "11:00 AM - 4:00 PM",
      },
    ],
    closed: "",
    email: "newdenversappletree@gmail.com",
    imageUrl: "./images/FMS_New_Denver.webp",
    mapLink: "https://maps.app.goo.gl/vtAj7rYJ1zzyiKjT7",
    placeId: "",
    siteUrl: "",
    coordinate: {
      lat: null,
      lon: null,
    },
  },
  {
    id: 8,
    name: "Gibsons Dock Sales",
    gm: "",
    address: "611 School Rd",
    city: "Gibsons",
    province: "BC",
    zip: "V0N 1V1",
    note: "Located at the Gibsons Harbour",
    phone: "(604) 330-5705",
    hours: [
      {
        day: "Wed - Sun",
        time: "12:00 PM - 7:00 PM",
      },
    ],
    closed: "Seasonal: From May to September",
    email: "gibsons@fmseafoods.com",
    imageUrl: "./images/FMS_Gibsons_Dockside.jpg",
    mapLink: "https://maps.app.goo.gl/rW1vZMrMpP32JTE7A",
    placeId: "",
    siteUrl: "",
    coordinate: {
      lat: null,
      lon: null,
    },
  },
];

export default storeData;
