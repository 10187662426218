import axios from "axios";

const apiUrl = "https://thefishermansmarket.ca/wp-json/wc/";
const consumerKey = process.env.REACT_APP_WC_API_KEY;
const consumerSecret = process.env.REACT_APP_WC_API_SECRET;
export const axiosOrdersInstance = axios.create({
  baseURL: apiUrl,
  auth: {
    username: consumerKey,
    password: consumerSecret,
  },
  params: {
    // _fields: 'id,name,price,regular_price,sale_price,categories,images,meta_data' // Specify only these fields in the response
  },
});

export const axiosPaymentGatewayInstance = axios.create({
  baseURL: apiUrl,
  // auth: {
  //     username: consumerKey,
  //     password: consumerSecret,
  // }
});
