import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { axiosProductsInstance } from "../dataFetch/axios.main";

import { useCart } from "../context/CartContext";
import SideBar from "../components/SideBar";
import SignUpButton from "../components/SignUpButtom";

import PopUp from "../components/PopUp";
import { Link } from "react-router-dom";
import "../css/ProductList.css";
import ProductListItem from "../components/ProductLIstItem";

import CardSkeleton from "../components/CardSkeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocationContext } from "../context/LocationContext";

// import { UtmContext } from '../context/UtmContext.js';

const PRICE_RANGES = [
  { label: "All", min: 0, max: Infinity },
  { label: "$0 - $50", min: 0, max: 50 },
  { label: "$50 - $100", min: 50, max: 100 },
  { label: "$100 - $200", min: 100, max: 200 },
  { label: "$200 and above", min: 200, max: Infinity },
];

const SearchResultList = () => {
  const { searchQuery } = useParams();
  const [products, setProducts] = useState([]);
  const [promotionProduct, setPromotionProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(12);

  const [filterPrice, setFilterPrice] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");

  const [selectedPriceRange, setSelectedPriceRange] = useState(PRICE_RANGES[0]);
  const { selectedLocation, setSelectedLocation } = useLocationContext();

  const alreadyRedirected =
    JSON.parse(localStorage.getItem("alreadyRedirected")) || false;

  useEffect(() => {
    console.log("Search in store:", selectedLocation);
    console.log("Search Query:", searchQuery);

    fetchAllProducts(selectedLocation);
  }, [selectedLocation, searchQuery]);

  // const utmParams = useContext(UtmContext);

  // useEffect(() => {
  //     const fetchCategories = async () => {
  //         setLoading(true);
  //         setError(null);

  //         try {
  //             const categoryUrl = 'https://thefishermansmarket.ca/wp-json/wc/v3/products/categories';
  //             const auth = {
  //                 username: consumerKey,
  //                 password: consumerSecret,
  //             };

  //             const response = await axios.get(categoryUrl, { auth: auth, params: { per_page: 100 } });
  //             setCategories(response.data);

  //         } catch (error) {
  //             setError(error.message);
  //         }
  //     };

  //     fetchCategories();

  // }, []);

  const fetchAllProducts = async (blogId) => {
    setLoading(true);
    setError(null);

    try {
      const paramsObject = alreadyRedirected
        ? { blog_id: blogId, search: searchQuery, size: 100 }
        : { search: searchQuery, size: 100 };
      const response = await axiosProductsInstance.get("", {
        params: paramsObject,
      });

      // Extract priorities from product attributes
      const productsData = response.data.map((product) => ({
        ...product,
        priority: getPriority(product),
      }));

      // Sort products by priority
      const sortedProducts = productsData.sort((a, b) => {
        const priorities = {
          oneday: 8,
          sale: 7,
          instore: 6,
          premium: 5,
          feature: 4,
          one: 3,
          two: 2,
          other: 1,
        };
        return priorities[b.priority] - priorities[a.priority];
      });
      setProducts(sortedProducts);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getPriority = (product) => {
    if (product.tags.some((tag) => tag.name === "Oneday")) return "oneday";
    if (product.on_sale) return "sale";
    if (product.virtual) return "instore";
    if (product.tags.some((tag) => tag.name === "Premium")) return "premium";
    if (product.tags.some((tag) => tag.name === "Feature")) return "feature";
    if (product.tags.some((tag) => tag.name === "1")) return "one";
    if (product.tags.some((tag) => tag.name === "2")) return "two";
    return "other";
  };

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    fetchAllProducts(categoryId);
  };

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct,
  );

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to top
  };

  const handlePriceRangeChange = (event) => {
    const priceRange = PRICE_RANGES.find(
      (range) => range.label === event.target.value,
    );
    setSelectedPriceRange(priceRange);
    setFilterPrice(priceRange.label !== "All");
  };
  const filterProductsByPrice = (products) => {
    return products.filter((product) => {
      const price = parseFloat(product.price);
      return price >= selectedPriceRange.min && price <= selectedPriceRange.max;
    });
  };

  const filteredProducts = filterProductsByPrice(products);

  const ProductDataList = (props) => {
    if (props.isloading) {
      return <CardSkeleton cards={12} />;
    }
    if (props.filterPrice) {
      if (filteredProducts.length === 0) {
        return "No Products Found";
      }
      return filteredProducts.map((product, index) => (
        <ProductListItem key={index} product={product} />
      ));
    } else {
      if (currentProducts.length === 0) {
        return "No Products Found";
      }
      return currentProducts.map((product, index) => (
        <ProductListItem key={index} product={product} />
      ));
    }
  };

  const Pagination = ({
    productsPerPage,
    totalProducts,
    currentPage,
    paginate,
  }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </li>
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`page-item ${currentPage === number ? "active" : ""}`}
            >
              <button className="page-link" onClick={() => paginate(number)}>
                {number}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${currentPage === pageNumbers.length ? "disabled" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === pageNumbers.length}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <div className="product-list">
      <div className="product-results-list-title">
        <h2>
          Search Results for "
          <span
            style={{ color: "#173050", fontFamily: "'pulpo-rust-100', serif" }}
          >
            {searchQuery}
          </span>
          "
        </h2>
      </div>

      {/* Filter Section */}
      {/* <div className="filter-section">
                <div className="filter">
                    <span>Category</span>
                    <select id="category-select" value={selectedCategory} onChange={handleCategoryChange}>
                        <option value="">All</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="filter">
                    <span>Price</span>
                    <select id="price-range-select" value={selectedPriceRange.label} onChange={handlePriceRangeChange}>
                        {PRICE_RANGES.map(range => (
                            <option key={range.label} value={range.label}>
                                {range.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div> */}

      {/* Products Container */}
      <div className="products-container">
        <ProductDataList isloading={loading} filterPrice={filterPrice} />
      </div>
      {products.length > productsPerPage && (
        <Pagination
          productsPerPage={productsPerPage}
          totalProducts={products.length}
          currentPage={currentPage}
          paginate={paginate}
        />
      )}
      <SignUpButton />
      <PopUp />
      <SideBar />
    </div>
  );
};

export default SearchResultList;
