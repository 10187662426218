const giftCardData = [
  {
    id: 1,
    name: "Gift Card (Digital)",
    imageUrl: "/images/products/king-crab-leg.jpg",
    imageUrl2: "/images/products/king-crab-leg2.jpg",
    imageUrl3: "/images/products/king-crab-leg3.jpg", // relative path to folder public
    description:
      "Shopping for someone else but not sure what to give them? Give them the gift of seafood with a gift card to Fishermans Market. Gift card recipient details and personal message",
    price: "25",
    priceRange: "25.00 - 200.00",
    quantity: 0,
  },

  {
    id: 2,
    name: "Gift Card (By Mail)",
    imageUrl: "/images/products/tiger-prawns.jpg",
    imageUrl2: "/images/products/tiger-prawns2.jpg",
    imageUrl3: "/images/products/tiger-prawns3.jpg",
    description:
      "Give those on your list the gift of seafood with a gift card to Fishermans Market. This is a physical gift card. It will be sent via standard Canada Post and will be sent separate fr.",
    price: "25",
    priceRange: "25.00 - 200.00",
    quantity: 0,
  },
];

export default giftCardData;
