import React from "react";
import { Link } from "react-router-dom";
import "../css/Ourfish.css";
import ourfishImg1 from "../images/FMS_ourfish-02.jpeg";
import ourfishImg2 from "../images/FMS_ourfish-03.jpeg";
import ourfishImg3 from "../images/commercial_fishing_2.jpg";

const Ourfish = () => {
  return (
    <>
      <div className="ourfish-wrapper">
        <h2>Fishing is Part of Who We Are</h2>
        <p style={{ textAlign: "center" }}>
          At Fisherman's Market, we work hard to ensure the highest quality
          standards, smooth-running operations, a safe and healthy workplace for
          our crew, and a product our customers can be proud of. But beyond
          that, we are lifelong fishermen, and when the ocean calls, we answer.
          Each year, as the seasons change, you’ll find our crew on the open
          seas.
        </p>
        <p style={{ textAlign: "center" }}>
          Whether we're pulling spot prawns in Haida Gwaii, battling the
          currents of Hecate Strait, or trolling for spring salmon at Rivers
          Inlet, we stay true to our roots and embrace the challenges of life at
          sea.
        </p>
      </div>
      <div className="ourfish-banner">
        <div className="ourfish-banner-wrapper">
          <div className="ourfish-banner-content">
            <p>
              While we don't catch every piece of BC seafood we sell, our
              decades of experience in fishing mean we know the people who do.
            </p>
            <p>
              We work hard to maintain relationships with fellow West Coast
              fishermen and women, ensuring that every piece of BC fish in our
              stores comes from a trusted, hardworking source. We stay true to
              authenticity, so that when our fish reaches your dinner table, it
              carries its own story--a story we hope your guests will
              appreciate.
            </p>
          </div>
        </div>
      </div>
      <div className="ourfish-wrapper" style={{ marginTop: "50px" }}>
        <div className="ourfish-section clearfix">
          <div
            className="image-container-right"
            style={{ aspectRatio: "4/3", maxWidth: "50%" }}
          >
            <img src={ourfishImg1} alt="Spot Prawns" />
          </div>
          <div className="ourfish-section-header">
            <h2 data-content="(Pandalus platyceros)">Spot Prawns</h2>
            <Link
              to="https://thefishermansmarket.ca/product/35495"
              className="ourfish-link"
            >
              SHOP THIS PRODUCT
            </Link>
          </div>
          <h3>Catch Method:</h3>
          <p>
            Spot prawns are sustainably caught using traps that minimize bycatch
            and preserve the seafloor ecosystem. Each trap is carefully
            monitored, ensuring minimal impact on the surrounding environment,
            allowing the population to replenish year after year.
          </p>
          <h3>Nutritional Information:</h3>
          <p>
            Spot prawns are an excellent source of protein, low in fat, and rich
            in essential nutrients like vitamin B12 and omega-3 fatty acids,
            which support heart and brain health. They are also high in
            antioxidants, particularly astaxanthin, which gives them their
            vibrant color.
          </p>
          <h3>What Makes Spot Prawns Unique:</h3>
          <p>
            Known for their sweet, delicate flavor and firm texture, BC spot
            prawns are considered a delicacy on the West Coast. These prawns are
            only available fresh for a short season each spring, making them a
            highly sought-after treat. Their sustainable harvest method and
            nutritional profile make them a top choice for those seeking a
            seafood option that’s both delicious and environmentally
            responsible.
          </p>
        </div>

        <div className="ourfish-section clearfix">
          <div className="image-container-left">
            <img src={ourfishImg2} alt="Lingcod" />
          </div>
          <div className="ourfish-section-header">
            <h2 data-content="(Ophiodon elongatus)">Lingcod</h2>
            <Link
              to="https://thefishermansmarket.ca/product/16164"
              className="ourfish-link"
            >
              SHOP THIS PRODUCT
            </Link>
          </div>
          <h3>Catch Method:</h3>
          <p>
            Lingcod are typically caught using hook-and-line or longline fishing
            methods, which allow for selective and sustainable harvesting. These
            methods also reduce bycatch, ensuring other marine species remain
            undisturbed.
          </p>
          <h3>Nutritional Information:</h3>
          <p>
            Lingcod is a lean fish, rich in protein, low in fat, and an
            excellent source of essential nutrients like selenium, vitamin B12,
            and omega-3 fatty acids. Its nutrient profile supports heart health,
            immunity, and energy metabolism.
          </p>
          <h3>What Makes Lingcod Unique:</h3>
          <p>
            Known for its firm texture and mild flavor, lingcod is versatile in
            the kitchen, pairing well with a variety of herbs and spices. The
            fish’s unique color—sometimes a striking blue—adds an element of
            surprise to the meal, though it cooks to a pure white. Lingcod is a
            staple of West Coast cuisine, appreciated for its taste and
            adaptability.
          </p>
        </div>

        <div className="ourfish-section clearfix">
          <div className="image-container-right">
            <img src={ourfishImg3} alt="Salmon" />
          </div>
          <div className="ourfish-section-header">
            <h2 data-content="(Oncorhynchus spp.)">Salmon</h2>
            <Link
              to="https://thefishermansmarket.ca/product/32102"
              className="ourfish-link"
            >
              SHOP THIS PRODUCT
            </Link>
          </div>
          <h3>Catch Method:</h3>
          <p>
            Salmon are caught using gillnets, trolling, and seine nets, with
            strict regulations in place to ensure sustainability. These methods
            protect salmon populations and reduce bycatch, supporting a balanced
            marine ecosystem.
          </p>
          <h3>Nutritional Information:</h3>
          <p>
            Salmon is rich in omega-3 fatty acids, protein, and essential
            nutrients like vitamin D, B vitamins, and potassium. It's renowned
            for its heart and brain health benefits, as well as its
            anti-inflammatory properties.
          </p>
          <h3>What Makes Lingcod Unique:</h3>
          <p>
            BC salmon is known for its distinctive, robust flavor and vibrant
            color. Salmon's versatility allows it to be enjoyed raw, smoked,
            grilled, or baked, making it a staple in many West Coast dishes. Its
            seasonal availability and nutritional profile have made it a
            celebrated choice for generations of seafood lovers.
          </p>
        </div>

        <div className="ourfish-section clearfix">
          <h3>THE 5 TYPES OF BC SALMON:</h3>
          <ol>
            <li>
              <span className="ourfish-section-title">
                Chinook (King) Salmon
              </span>{" "}
              - Oncorhynchus tshawytscha
              <p>
                Known for its high fat content and rich, buttery flavor, Chinook
                is the largest species of Pacific salmon. It's prized for its
                tender texture and is often enjoyed grilled or smoked.
              </p>
            </li>
            <li>
              <span className="ourfish-section-title">
                Sockeye (Red) Salmon
              </span>{" "}
              - Oncorhynchus nerka
              <p>
                With its deep red flesh and firm texture, sockeye salmon has a
                bold, rich flavor. It's popular in sushi, as well as for
                canning, grilling, and smoking.
              </p>
            </li>
            <li>
              <span className="ourfish-section-title">
                Coho (Silver) Salmon
              </span>{" "}
              - Oncorhynchus kisutch
              <p>
                Coho salmon has a slightly milder flavor than sockeye, with a
                medium fat content and delicate texture. Its versatility makes
                it ideal for various cooking methods, from baking to poaching.
              </p>
            </li>
            <li>
              <span className="ourfish-section-title">
                Pink (Humpback) Salmon
              </span>{" "}
              - Oncorhynchus gorbuscha
              <p>
                The most abundant Pacific salmon, pink salmon is light in color
                and flavor, with a lower fat content. Unlike the typical pink
                salmon seen in grocery stores, our pink salmon is frozen at sea
                to preserve its freshness, making it a premium product. It
                boasts bright blue and silver skin, delivering a high-quality
                option with a clean, mild flavor. This unique handling process
                ensures a difference you can taste, setting our pink salmon
                apart in quality and freshness.
              </p>
            </li>
            <li>
              <span className="ourfish-section-title">
                Chum (Keta or Dog) Salmon
              </span>{" "}
              - Oncorhynchus keta
              <p>
                Known for its firm flesh and mild taste, chum salmon is often
                used in smoking and curing. Unlike most chum salmon on the
                market, we only source product from premium runs, selecting
                those with bright silver skin, also known as "silver brites."
                With its high oil content, it's an excellent choice for smoking,
                but it's also a flavorful and affordable option for family
                meals, offering great value without compromising on quality.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default Ourfish;
