import { Route, Navigate } from "react-router-dom";
import { useUser } from "./context/UserContext";

const PrivateRoute = ({ children }) => {
  const { isLoggedIn } = useUser();

  return isLoggedIn ? children : <Navigate to="/customer/sign-in" replace />;
};

export default PrivateRoute;
