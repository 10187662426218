import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bannerImage from "../images/banner.jpg";
import bannerLogo from "../images/logo.svg";
import "../css/Banner.css";

const Banner = ({ text1, text2, text3, buttonText }) => {
  const [logoAnimation, setLogoAnimation] = useState("banner-logo");
  const [text1Animation, setText1Animation] = useState("banner-text2");
  const [text2Animation, setText2Animation] = useState("banner-text3");
  const [buttonAnimation, setButtonAnimation] = useState("banner-button");

  useEffect(() => {
    setLogoAnimation("banner-logo fade-in");
    setText1Animation("banner-text2 slide-up");
    setText2Animation("banner-text3 slide-up");
    setButtonAnimation("banner-button slide-up");
  }, []);

  useEffect(() => {
    const parallaxScrool = () => {
      const bannerBg = document.getElementById("bannerBg");
      const value = window.scrollY;
      if (value < 720) {
        bannerBg.style.transform = `translateY(${value * 0.3}px)`;
      }
    };

    window.addEventListener("scroll", parallaxScrool);

    return () => {
      window.removeEventListener("scroll", parallaxScrool);
    };
  }, []);

  return (
    <div className="banner">
      <div id="bannerBg" className="banner-wrapper"></div>

      <div className="banner-content">
        {/* <div className="banner-text1">{text1}</div> */}
        <div className={logoAnimation}>
          <img src={bannerLogo} alt="logo" />
        </div>
        <h2 className={text1Animation}>{text2}</h2>
        <h2 className={text2Animation}>{text3}</h2>
        <Link to={"/shop"} className={buttonAnimation}>
          {buttonText}
        </Link>
      </div>
    </div>
  );
};

export default Banner;
