import axios from "axios";

const apiUrl = "https://thefishermansmarket.ca/wp-json/wc/";
const consumerKey = process.env.REACT_APP_API_KEY;
const consumerSecret = process.env.REACT_APP_API_SECRET;

export const axiosProductsInstance = axios.create({
  baseURL: "https://thefishermansmarket.ca/wp-json/feature-api/v1/product",
  // auth: {
  //     username: consumerKey,
  //     password: consumerSecret,
  // },
  params: {
    // _fields: 'id,name,price,regular_price,sale_price,categories,images,meta_data' // Specify only these fields in the response
  },
});

export const axiosUserInstance = axios.create({
  baseURL: apiUrl,
  // auth: {
  //     username: process.env.REACT_APP_WC_API_KEY,
  //     password: process.env.REACT_APP_WC_API_SECRET,
  // },
  params: {
    // _fields: 'id,name,price,regular_price,sale_price,categories,images,meta_data' // Specify only these fields in the response
  },
});
export const axiosUserDetailsInstance = axios.create({
  baseURL: apiUrl,
  auth: {
    username: process.env.REACT_APP_WC_API_KEY,
    password: process.env.REACT_APP_WC_API_SECRET,
  },
  params: {
    // Specify only these fields in the response
  },
});
