import { Link } from "react-router-dom";
import "../../css/Cart.css";

const EmptyCart = ({ action }) => {
  return (
    <div className="cart-empty">
      <h2 className="cart-title">Your Cart</h2>

      <div className="cart-content">
        <h3>YOUR CART IS EMPTY</h3>
        <p>
          Hook some fresh catches for your cart, and let the culinary voyage
          unfold from ocean to plate, a seafood delight awaits!
        </p>
        <Link to="/shop" className="cart-continue-button">
          CONTINUE SHOPPING
        </Link>
      </div>
    </div>
  );
};
export default EmptyCart;
