import React from "react";
import SignUpButton from "../components/SignUpButtom";
import "../css/Terms.css";
import PopUp from "../components/PopUp";

export default function Shipping() {
  return (
    <div className="terms">
      {/* <div className="terms-banner">
                <h2>Shipping</h2>
            </div> */}

      <div className="terms-wrapper">
        <div className="terms-body">
          <div className="terms-body-section">
            <h3>Shipping Information for Fisherman's Market</h3>
            <p>
              Welcome to Fisherman's Market, your trusted source for the finest
              frozen seafood delivered directly to your door. We specialize in
              providing freshly frozen products, ensuring top-quality seafood
              with our efficient packing and shipping methods. Discover our
              shipping policies tailored for the Lower Mainland of Vancouver and
              the Okanagan region.
            </p>
            <ol>
              <li>
                <h4>Lower Mainland of Vancouver:</h4>
                <p>
                  <b>Areas Covered:</b> Our delivery network spans Vancouver
                  Proper, Downtown, East Vancouver, South Vancouver, West
                  Vancouver, UBC (University of British Columbia), North
                  Vancouver, West Vancouver (repeated for emphasis), Deep Cove,
                  Horseshoe Bay, Burnaby, New Westminster, Coquitlam, Port
                  Coquitlam, and Port Moody.
                </p>
                <p>
                  <b>Shipping Costs:</b>
                </p>
                <p>
                  <b>Flat Rate:</b> All orders are delivered at a flat rate of
                  $30.
                </p>
                <p>
                  <b>Free Shipping:</b> Orders over $250 qualify for free
                  delivery, offering great value for your bulk purchases.
                </p>
                <p>
                  <b>Packing and Delivery:</b> Deliveries are meticulously
                  packed using gel packs and sturdy cardboard to maintain the
                  integrity of the frozen products. Our shipping partner, Bobby
                  Cool Courier Service, ensures prompt and safe delivery without
                  the use of refrigerated transport.
                </p>
              </li>
              <li>
                <h4>Okanagan Region:</h4>
                <p>
                  <b>Areas Covered:</b> We extend our services from Kelowna to
                  Penticton and from Kelowna to Salmon Arm, ensuring wide
                  coverage in the Okanagan. <b>Except inside Kelowna.</b>
                </p>
                <p>
                  Here is the list of the shipping cities: West Kelowna,
                  Penticton, Summerland, Peachland, Lake Country, Vernon, Salmon
                  Arm, and Armstrong
                </p>
                <p>
                  <b>Shipping Costs:</b>
                </p>
                <p>
                  <b>Flat Rate:</b> A consistent flat rate of $25 is charged for
                  all deliveries.
                </p>
                <p>
                  <b>Free Shipping:</b> Enjoy free shipping on orders exceeding
                  $250.
                </p>
              </li>
            </ol>
            <h4>Additional Notes:</h4>
            <p>
              <b>Quality Assurance:</b> Our seafood is not just fresh; it's
              freshly frozen at peak quality to lock in flavor and nutrients.
              Each order is expertly packed to withstand the shipping process
              without the need for refrigerated transport.
            </p>
            <p>
              <b>Expansion Plans:</b> As we continue to grow, keep an eye out
              for more cities being added to our delivery routes. We’re excited
              to expand our reach and bring our quality seafood to more homes.
            </p>
            <p>
              Thank you for choosing Fisherman's Market. Our commitment to
              quality and customer satisfaction ensures that you receive the
              best frozen seafood, delivered with care. If you have any
              questions about our shipping methods or need assistance with your
              order, please contact our customer service team. Enjoy premium
              seafood delivered with integrity, directly from Fisherman’s
              Market.
            </p>
          </div>
        </div>
      </div>
      <SignUpButton />
      <PopUp />
    </div>
  );
}
