import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faCartShopping,
  faUser,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { useCart } from "../../context/CartContext";

import "../../css/CheckoutSummaryItem.css";

const CheckoutSummaryItem = ({ item }) => {
  const {
    removeItemFromCart,
    addItemToCart,
    removeAllItemsOfType,
    calculateItemTotalPrice,
  } = useCart();
  return (
    <div className="product-item-container">
      <img className="product-image-cart" src={item.thumbnail} />
      <div className="text-item-name">
        <span>
          {item.name}
          {item.blog_on_sale && <span className="sale-tag">Sale</span>}
        </span>
        <span style={{ fontWeight: "450" }}>x {item.quantity}</span>
      </div>
      <div className="price-container">
        <div className="text-total-price-value">
          ${calculateItemTotalPrice(item)}
        </div>
      </div>
    </div>
  );
};
export default CheckoutSummaryItem;
