import React from "react";
import { Link } from "react-router-dom";
import "../css/FestInfo.css";

export default function FestInfo() {
  return (
    <div className="fest-info">
      <div className="fest-info-banner">
        <h2>
          <img src="./images/FMS_horizontal-logo-RGB-full-colour.svg" />
        </h2>
      </div>

      <div className="fest-info-wrapper" style={{ marginTop: "30px" }}>
        <div className="fest-info-body">
          <h3>Something Special is Coming This Thanksgiving!</h3>
          <p>
            Thanksgiving is almost here, and we’re preparing some exciting
            seafood deals just for you! From <b>October 8th</b> to{" "}
            <b>October 13th</b>, Fisherman’s Market will be offering exclusive
            offers and surprises to help make your holiday celebration even more
            special.
          </p>
          <h4>What’s Coming?</h4>
          <p>
            We can’t reveal all the details yet, but trust us, you won’t want to
            miss these fantastic deals. Whether you’re hosting a family
            gathering or just looking to enjoy the freshest seafood, we’ve got
            something for everyone.
          </p>
          <h4>Mark Your Calendar</h4>
          <p>
            Keep an eye on your inbox and get ready for incredible seafood
            savings starting soon. You don’t want to miss out!
          </p>
          <h4>Visit Us!</h4>
          <p>
            Find your nearest Fisherman’s Market location and start preparing
            for a seafood-filled Thanksgiving. Our friendly staff will be happy
            to assist with any questions!
          </p>
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to="/locations" className="fest-info-button">
            Find a Store Near You
          </Link>
        </div>
      </div>

      <div className="fest-info-footer"></div>
    </div>
  );
}
