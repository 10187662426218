import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCart } from "../context/CartContext";
import SideBar from "../components/SideBar";
import { Link } from "react-router-dom";
import giftCardData from "../data/giftCardData";

import "../css/ProductList.css";
import SignUpButton from "../components/SignUpButtom";

const GiftCard = () => {
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");

  // SideBar Implementation
  const [isSideBarVisible, setIsSideBarVisible] = useState(false);

  const { addItemToCart } = useCart();

  const fetchData = () => {
    // axios.get('http://192.168.1.87:8888/admin_FMS/wp-json/wc/store/products').then(res => {
    //     // console.log(res.data);
    //     const serializedData = removeHtmlFromProducts(res.data);
    //     setProducts(serializedData);
    // });
    setProducts(giftCardData);
    // return productData
  };

  function removeHtmlFromProducts(products) {
    return products.map((product) => {
      // console.log(prod)
      product.name = product.name ? product.name.replace(/&#8211;/g, "") : "-";
      // Remove HTML elements from the 'description' property
      product.description = product.description
        ? product.description.replace(/<\/?[^>]+(>|$)/g, "")
        : "";
      product.description = product.description.substr(0, 400);
      // Remove HTML elements from the 'short_description' property
      product.short_description = product.short_description
        ? product.short_description.replace(/<\/?[^>]+(>|$)/g, "")
        : "";

      return product;
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggleSideBar = () => {
    setIsSideBarVisible(!isSideBarVisible);
  };

  const handleAddToCart = (product) => {
    addItemToCart(product);
    if (!isSideBarVisible) {
      toggleSideBar();
    }
  };

  const categories = [
    "Shrimp",
    "Crab",
    "Scallops",
    "Salmon",
    "Tuna",
    "Pollock",
    "Rockfish",
    "Meat",
    "Spices",
    "Specialty Items",
  ];

  const sizes = [
    "1lb.",
    "2lb.",
    "3lb.",
    "4lb.",
    "5lb.",
    "6lb.",
    "10lb.",
    "3oz.",
    "4oz.",
    "5oz.",
    "6oz.",
    "Other size",
  ];

  const prices = [
    "Below $10",
    "$10.00-$20.00",
    "$20.01-$30.00",
    "$30.01-$40.00",
    "$40.01-$50.00",
    "$50.01-$60.00",
    "$60.01-$70.00",
    "$70.01 and more",
  ];
  return (
    <div className="product-list">
      <div
        className="promotion-banner"
        style={{ backgroundImage: "url(/images/products/gift-card.webp)" }}
      >
        <div className="banner-content">
          <h1>Gift Cards</h1>
          {/* <span className="price-highlight">$39.99/LB</span> */}
          <button className="see-detail-button">SEE DETAIL</button>
        </div>
      </div>
      {/* <div className="filter-section">
                <div className="filter">
                    <span>Category</span>
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <option value="">All</option>
                        {categories.map((category, index) => (
                            <option key={index} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="filter">
                    <span>Size</span>
                    <select
                        value={selectedSize}
                        onChange={(e) => setSelectedSize(e.target.value)}
                    >
                        <option value="">All</option>
                        {sizes.map((size, index) => (
                            <option key={index} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="filter">
                    <span>Price</span>
                    <select
                        value={selectedPrice}
                        onChange={(e) => setSelectedPrice(e.target.value)}
                    >
                        <option value="">All</option>
                        {prices.map((price, index) => (
                            <option key={index} value={price}>
                                {price}
                            </option>
                        ))}
                    </select>
                </div>
            </div> */}

      <div className="products-container">
        {products.map((product) => (
          <div key={product.id} className="products-container-list">
            <div className="product-image-wrapper">
              <div
                className="product-image"
                style={{ backgroundImage: "url(" + product.imageUrl + ")" }}
              ></div>
            </div>
            <div className="product-info">
              <Link
                to={`/product/${product.id}`}
                className="product-list-title"
              >
                {product.name}
              </Link>
              {/* <p>{product.description}</p> */}
              <div>
                <span className="price">${product.priceRange}</span>
                <span className="price-uom">each</span>
              </div>
            </div>
            <div className="product-list-purchase">
              <input
                type="number"
                id="quantity"
                name="quantity"
                min="1"
                defaultValue="1"
              />
              <button
                className="product-list-cart"
                onClick={() => handleAddToCart(product)}
              >
                Add to Cart
              </button>
            </div>
          </div>
        ))}
      </div>

      <SideBar isVisible={isSideBarVisible} toggleSideBar={toggleSideBar} />
      <SignUpButton />
    </div>
  );
};

export default GiftCard;
