import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import CheckoutSummaryItem from "../../components/cart/CheckoutSummaryItem";
import CartContent from "../../components/cart/CartContent";
import { useCart } from "../../context/CartContext";
import { useCheckout } from "../../context/CheckoutContext";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import EmptyCart from "../../components/cart/EmptyCart";
import "../../css/Cart.css";
import Recommends from "../../components/Recommends";
import { useUser } from "../../context/UserContext";

const CheckoutSuccess = () => {
  // Contexts
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails, isLoggedIn } = useUser();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const order_id = urlSearchParams.get("master_order_id");
  const { clearCart } = useCart();
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "purchase",
    });

    clearCart();
  }, []);

  // let data = location.state?.orderData
  // let data = location.state?.orderData
  // console.log(data)
  return (
    <div className="cart">
      <div className="cart-empty">
        <h2 className="cart-title">Success</h2>

        <div className="cart-content">
          <h3>
            Order #
            {order_id ? order_id : location.state?.orderData.master_order_id}{" "}
            Successfully Processed
          </h3>
          <p>
            Your latest haul from the ocean's bounty is on its way! Embark on a
            culinary adventure that bridges the sea to your dining table,
            savoring the delight of fresh seafood.
          </p>
          <p>
            Ready to dive deeper?{" "}
            <a href="https://thefishermansmarket.ca/shop">Continue exploring</a>{" "}
            and cast your net for more treasures,
            {isLoggedIn ? (
              <a href="https://thefishermansmarket.ca/customer/account">
                view your orders
              </a>
            ) : (
              ""
            )}
            to track your current catch as it sails towards you.
          </p>

          <Link to="/shop" className="cart-continue-button">
            CONTINUE SHOPPING
          </Link>
        </div>
        <Recommends />
      </div>
    </div>
  );
};

export default CheckoutSuccess;

// return orderData.map((order) => (
//     <div key={order.id} className="column-item">
//     </div>
// ));
