import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faXmark,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import storeData from "../data/storeData";
import "../css/StoreSelector.css";
import showSelector from "./Navbar";
import { useLocationContext } from "../context/LocationContext";
import { set } from "react-hook-form";

const GOOGLE_MAPS_API_KEY = "AIzaSyABT6f796x4DLRYm1ugyqWf5PqJt6hJkck";

// Helper function for distance calculation
function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Earth's radius in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  return R * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}

const StoreSelector = () => {
  const [postalCode, setPostalCode] = useState("");
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState("");
  const [apiError, setApiError] = useState("");
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [notice, setNotice] = useState("");
  const [storeNameSelected, setStoreNameSelected] = useState("Nelson");
  const [storeSelected, setStoreSelected] = useState({ hours: [] });

  const selectorRef = useRef(null);
  const [locationError, setLocationError] = useState(null);

  const {
    selectedLocation,
    setSelectedLocation,
    showSelector,
    setShowSelector,
    isOpenedInProductList,
    setIsOpenedInProductList,
  } = useLocationContext();

  useEffect(() => {
    const handleStoreList = () => {
      const store = storeData.find((store) => store.name === storeNameSelected);
      setStoreSelected(store);
    };
    handleStoreList();
  }, [storeNameSelected]);

  // Load Google Maps API
  useEffect(() => {
    if (!window.google && !document.querySelector("#google-maps-script")) {
      const script = document.createElement("script");
      script.id = "google-maps-script";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => setGoogleMapsLoaded(true);
      document.head.appendChild(script);
    } else if (window.google) {
      setGoogleMapsLoaded(true);
    }
  }, []);

  // Format postal code as user types (A1A 1A1)
  const formatPostalCode = (input) => {
    const cleaned = input.replace(/[^A-Za-z0-9]/g, "").toUpperCase();
    if (cleaned.length > 3) {
      return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)}`;
    }
    return cleaned;
  };

  // Validate Canadian postal code format
  const isValidPostalCode = (code) => {
    const postalRegex =
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] ?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
    return postalRegex.test(code);
  };

  const handleInputChange = (e) => {
    const formatted = formatPostalCode(e.target.value);
    setPostalCode(formatted.slice(0, 7));
    setError("");
    setApiError("");
    if (e.target.value === "") {
      setResults([]);
      setNotice("");
    }
  };

  // Fetch coordinates using Google Maps Geocoding API
  const fetchPostalCodeCoordinates = async (postalCode) => {
    if (!googleMapsLoaded) {
      throw new Error("Google Maps API is not loaded yet");
    }

    const geocoder = new window.google.maps.Geocoder();

    try {
      const result = await new Promise((resolve, reject) => {
        geocoder.geocode(
          {
            address: `${postalCode}, Canada`,
            componentRestrictions: {
              country: "CA",
              postalCode: postalCode.replace(/\s/g, ""),
            },
          },
          (results, status) => {
            if (status === "OK" && results && results[0]) {
              resolve(results[0]);
            } else {
              reject(new Error("Could not find location for this postal code"));
            }
          },
        );
      });

      return {
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng(),
        formattedAddress: result.formatted_address,
      };
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleEnterSearch = (e) => {
    if (e.key === "Enter") {
      handleSearch(e);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsSearching(true);
    setError("");
    setApiError("");
    setResults([]);

    // Validate input
    if (!postalCode.trim()) {
      setError("Please enter a postal code");
      setIsSearching(false);
      return;
    }

    if (!isValidPostalCode(postalCode)) {
      setError("Please enter a valid Canadian postal code (e.g., M5V 2T6)");
      setIsSearching(false);
      return;
    }

    try {
      // Get coordinates for the entered postal code
      const { latitude, longitude, formattedAddress } =
        await fetchPostalCodeCoordinates(postalCode);

      // Calculate distance to each store
      const storesWithDistance = storeData
        .map((store) => ({
          ...store,
          distance: calculateDistance(
            latitude,
            longitude,
            store.coordinate.lat,
            store.coordinate.lon,
          ),
        }))
        .sort((a, b) => a.distance - b.distance)
        .filter((store) => store.distance <= 70); // Only show stores within 70km

      if (storesWithDistance.length === 0) {
        setNotice("No stores found within 70km of this postal code");
      }

      setResults(storesWithDistance);
    } catch (error) {
      setApiError(error.message);
    } finally {
      setIsSearching(false);
    }
  };

  const handleRequestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          redirectToNearestStore(latitude, longitude);
        },
        (error) => {
          console.warn("Location access denied or error: ", error.message);
          setLocationError(
            "Location access denied. Please allow location services.",
          );
        },
      );
    } else {
      setLocationError("Geolocation is not supported by this browser.");
    }
  };

  const redirectToNearestStore = (latitude, longitude) => {
    const nearestStoreId = findNearestStore(latitude, longitude);

    if (nearestStoreId && selectedLocation !== nearestStoreId) {
      // Set the redirection flag to avoid repeated redirects
      localStorage.setItem("alreadyRedirected", true);
      // Change the selectedLocation in LocationContext
      setSelectedLocation(nearestStoreId);
    }
  };

  const findNearestStore = (lat, lon) => {
    // Find the closest store
    const distances = storeData.map((store) => ({
      ...store,
      distance: calculateDistance(
        lat,
        lon,
        store.coordinate.lat,
        store.coordinate.lon,
      ),
    }));
    distances.sort((a, b) => a.distance - b.distance);

    switch (distances[0].name) {
      case "Nelson":
        return "ne";
      case "Kelowna":
        return "ke";
      case "Gibsons":
        return "gi";
      case "Kamloops":
        return "ka";
      case "West Vancouver":
        return "wv";
      case "Burnaby":
        return "bu";
      default:
        return null;
    }
  };

  const handleStoreChange = (e) => {
    setStoreNameSelected(e.target.value);
  };

  const handleStoreSelect = (storeName) => {
    console.log("Selected store:", storeName);
    switch (storeName) {
      case "Nelson":
        setSelectedLocation("ne");
        break;
      case "Kelowna":
        setSelectedLocation("ke");
        break;
      case "Gibsons":
        setSelectedLocation("gi");
        break;
      case "Kamloops":
        setSelectedLocation("ka");
        break;
      case "West Vancouver":
        setSelectedLocation("wv");
        break;
      case "Burnaby":
        setSelectedLocation("bu");
        break;
      default:
        setSelectedLocation("ke");
        break;
    }
    setShowSelector(false);
    setIsOpenedInProductList(false);
    localStorage.setItem("alreadyRedirected", true);
  };

  useEffect(() => {
    // Disable background scroll when StoreSelector is shown
    document.body.style.overflow = "hidden";
    // Cleanup function to re-enable scroll when StoreSelector is closed
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setShowSelector(false);
        setIsOpenedInProductList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return isOpenedInProductList ? (
    <div className="store-selector-overlay sm">
      <div ref={selectorRef} className="store-selector-wrapper">
        <div className="store-selector-container">
          <div className="store-selector-header">
            <div className="store-selector-header-label">
              <h2>Choose Your Nearest Store</h2>
              <button
                onClick={() => {
                  setShowSelector(false);
                  setIsOpenedInProductList(false);
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faXmark} />{" "}
              </button>
            </div>

            <div className="store-selector-header-searchbar">
              <p>
                This helps us show you the right products and availability for
                your area.
              </p>
            </div>

            <div className="store-selector-list-container">
              <div className="store-selector-list-item-wrapper">
                <select
                  name="store"
                  id="store"
                  className="store-selector-lis-select"
                  value={storeNameSelected}
                  onChange={handleStoreChange}
                >
                  {storeData
                    .filter((item) => item.id !== 7 && item.id !== 8)
                    .map((store, index) => (
                      <option key={index} value={store.name}>
                        {store.name}
                      </option>
                    ))}
                </select>

                <div className="store-selector-list-details">
                  <div className="store-selector-list-address">
                    <a
                      href={storeSelected.mapLink}
                      target="_blank"
                      rel="noreferrer"
                      title="Google Map"
                    >
                      {storeSelected.address}, {storeSelected.city},{" "}
                      {storeSelected.province} {storeSelected.zip}
                    </a>
                  </div>
                  <div className="store-selector-list-phone">
                    <p style={{ marginBottom: "1rem" }}>
                      {storeSelected.phone}
                    </p>
                  </div>
                  <div className="store-selector-list-phone">
                    {storeSelected.hours.map((hour, index) => (
                      <p key={index}>
                        <span style={{ fontWeight: "500" }}>{hour.day}. </span>
                        <span style={{ color: "#555" }}>{hour.time}</span>
                      </p>
                    ))}
                  </div>
                </div>

                <div className="store-selector-list-setdefault">
                  <button onClick={() => handleStoreSelect(storeSelected.name)}>
                    Select this store
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="store-selector-overlay">
      <div ref={selectorRef} className="store-selector-wrapper">
        <div className="store-selector-container">
          <div className="store-selector-header">
            <div className="store-selector-header-label">
              <h2>Find the Nearest Store</h2>
              <button
                onClick={() => {
                  setShowSelector(false);
                  setIsOpenedInProductList(false);
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faXmark} />{" "}
              </button>
            </div>

            <div className="store-selector-header-searchbar">
              <div className="store-selector-header-searchbar-container">
                <input
                  type="text"
                  value={postalCode}
                  onKeyDown={handleEnterSearch}
                  onChange={handleInputChange}
                  placeholder="Enter postal code"
                />
                <span onClick={handleSearch}>
                  {isSearching ? (
                    "Searching..."
                  ) : (
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  )}
                </span>
              </div>
              <button
                className="store-selector-header-searchbar-location"
                onClick={handleRequestLocation}
              >
                <FontAwesomeIcon icon={faLocationArrow} />
              </button>
            </div>
            {locationError && <p>{locationError}</p>}
            {error && <p>{error}</p>}
            {apiError && <p>{apiError}</p>}
          </div>

          <div className="store-selector-list-container">
            {results.length > 0 ? (
              <ul className="store-selector-list">
                {results
                  .filter((store) => store.id !== 7 && store.id !== 8)
                  .map((store, index) => (
                    <li key={index} className="store-selector-list-item">
                      <div className="store-selector-list-item-wrapper">
                        <div className="store-selector-list-name">
                          <h3>{store.name}</h3>
                        </div>
                        <div className="store-selector-list-details">
                          <div className="store-selector-list-time">
                            {store.hours.map((hour, index) => (
                              <p key={index}>
                                <span style={{ fontWeight: "500" }}>
                                  {hour.day}.{" "}
                                </span>
                                <span style={{ color: "#555" }}>
                                  {hour.time}
                                </span>
                              </p>
                            ))}
                          </div>
                          <div className="store-selector-list-address">
                            <a
                              href={store.mapLink}
                              target="_blank"
                              rel="noreferrer"
                              title="Google Map"
                            >
                              {store.address}, {store.city}, {store.province}{" "}
                              {store.zip}
                            </a>
                          </div>
                          <div className="store-selector-list-phone">
                            <p>{store.phone}</p>
                          </div>
                        </div>
                        <div className="store-selector-list-setdefault">
                          <button onClick={() => handleStoreSelect(store.name)}>
                            Select this store
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            ) : (
              <ul className="store-selector-list">
                {notice && (
                  <p style={{ padding: "0 40px", margin: "1rem 0 0" }}>
                    {notice}
                  </p>
                )}
                {storeData
                  .filter((store) => store.id !== 7 && store.id !== 8)
                  .map((store, index) => (
                    <li key={index} className="store-selector-list-item">
                      <div className="store-selector-list-item-wrapper">
                        <div className="store-selector-list-name">
                          <h3>{store.name}</h3>
                        </div>
                        <div className="store-selector-list-details">
                          <div className="store-selector-list-time">
                            {store.hours.map((hour, index) => (
                              <p key={index}>
                                <span style={{ fontWeight: "500" }}>
                                  {hour.day}.{" "}
                                </span>
                                <span style={{ color: "#555" }}>
                                  {hour.time}
                                </span>
                              </p>
                            ))}
                          </div>
                          <div className="store-selector-list-address">
                            <a
                              href={store.mapLink}
                              target="_blank"
                              rel="noreferrer"
                              title="Google Map"
                            >
                              {store.address}, {store.city}, {store.province}{" "}
                              {store.zip}
                            </a>
                          </div>
                          <div className="store-selector-list-phone">
                            <p>{store.phone}</p>
                          </div>
                        </div>
                        <div className="store-selector-list-setdefault">
                          <button onClick={() => handleStoreSelect(store.name)}>
                            Select this store
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreSelector;
