import React from "react";
import "../css/Sustainability.css";
import { Link } from "react-router-dom";
import subscribeImg from "../images/FMicon_subscribe.svg";
import shopnowImg from "../images/FMicon_shopnow.svg";
import learnmoreImg from "../images/FMicon_learnmore.svg";

const Sustainability = () => {
  return (
    <div className="sustainability">
      <div className="sustainability-wrapper">
        <div className="section">
          <img src={subscribeImg} />
          <p>
            Stay informed on all of our sales, promotions, giveaways, and events
          </p>
          <a className="learn-more" href="#SignUp">
            SUBSCRIBE
          </a>
        </div>
        <div className="section">
          <img src={shopnowImg} />
          <p>Offering you the best quality seafood in the industry</p>
          <Link className="shop-now" to={"/shop"}>
            SHOP NOW
          </Link>
        </div>
        <div className="section">
          <img src={learnmoreImg} />
          <p>
            From our fishing vessels to our markets, a lifetime of seafood
            knowledge
          </p>
          <Link className="learn-more" to={"/about-us"}>
            LEARN MORE
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sustainability;
