import React, { useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import { useNavigate, Link, NavLink } from "react-router-dom";
import {
  faBox,
  faAddressCard,
  faTags,
  faGear,
  faRightFromBracket,
  faClose,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../css/Account.css";
import { axiosUserInstance } from "../../dataFetch/axios.main";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { axiosOrdersInstance } from "../../dataFetch/resourceFetcher";
import { useCart } from "../../context/CartContext";

import toast, { Toaster } from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";
import { set } from "react-hook-form";
import { useLocationContext } from "../../context/LocationContext";

// import { useForm } from "react-hook-form"

// Simulate gift cards data
const mockGiftCards = [{ id: 1, amount: "$100", expire: "01.01.2025" }];

// Simulate coupons data
const mockCoupons = [
  { id: 1, code: "DISC10", discount: "10%", expire: "01.01.2025" },
];
const mockUserData = {
  email: "user@example.com",
  name: "John Doe",
  shippingAddress: "123 Main St, Anytown, AN",
  phone: "123-456-7890",
  // Let's say this is the user's saved credit card information
  creditCard: {
    number: "**** **** **** 1234",
    name: "John Doe",
    expiry: "12/24",
    cvv: "123",
  },
};

const MyAccount = () => {
  const [userInfo, setUserInfo] = useState({});
  const [userBillingInfo, setUserBillingInfo] = useState({});
  const [userShippingInfo, setUserShippingInfo] = useState({});

  const {
    user,
    signOut,
    userDetails,
    setUserDetails,
    getCustomerDetails,
    validateUser,
    isLoggedIn,
  } = useUser();
  const { addItemsToCart, getItemsForCart } = useCart();
  const [activeTab, setActiveTab] = useState("");
  const [menuClass, setMenuClass] = useState("account-wrapper");
  const [viewingOrder, setViewingOrder] = useState(null); // Add a new status to track the currently viewed order
  const [userData, setUserData] = useState(mockUserData); // Initialize state with simulated user data
  const [isloading, setIsloading] = useState(false);
  const [isCustomerEdit, setIsCustomerEdit] = useState(false);
  const [isShippingEdit, setIsShippingEdit] = useState(false);
  const [isBillingEdit, setIsBillingEdit] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [status, setStatus] = useState("all");

  const alreadyRedirected =
    JSON.parse(localStorage.getItem("alreadyRedirected")) || false;
  const { setShowSelector, setIsOpenedInProductList } = useLocationContext();

  useEffect(() => {
    if (userDetails) {
      const savedUserInfo = JSON.parse(localStorage.getItem("userDetails"));
      const savedUserBillingInfo = JSON.parse(
        localStorage.getItem("userDetails"),
      )?.billing;
      const savedUserShippingInfo = JSON.parse(
        localStorage.getItem("userDetails"),
      )?.shipping;

      // console.log(savedPersonalInformation)

      const info = savedUserInfo
        ? savedUserInfo
        : {
            email: "",
            first_name: "",
            last_name: "",
          };

      const billing = savedUserBillingInfo
        ? savedUserBillingInfo
        : {
            first_name: "",
            last_name: "",
            address_1: "",
            city: "",
            postcode: "",
            country: "Canada",
            state: "British Columbia",
            email: "",
            phone: "",
          };

      const shipping = savedUserShippingInfo
        ? savedUserShippingInfo
        : {
            first_name: "",
            last_name: "",
            address_1: "",
            city: "",
            postcode: "",
            country: "Canada",
            state: "British Columbia",
            phone: "",
          };
      setUserInfo(info);
      setUserBillingInfo(billing);
      setUserShippingInfo(shipping);
      setPersonalInformation(savedUserInfo);
    }
  }, [validateUser]);

  useEffect(() => {
    // const handleResize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 768) {
      setActiveTab("orders");
    } else {
      setActiveTab("");
    }
    // };

    // // Set the initial state
    // handleResize();

    // // Add event listener
    // window.addEventListener('resize', handleResize);

    // // Cleanup event listener on component unmount
    // return () => {
    //     window.removeEventListener('resize', handleResize);
    // };
  }, []);

  const handleOrderStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const filteredOrders =
    status === "all"
      ? orderData
      : orderData.filter((order) => order.status === status);
  // Simulate user data

  const navigate = useNavigate();

  const getOrders = useMutation({
    mutationFn: (id) => {
      setIsloading(true);
      console.log("Fetching Orders. id: ", id);
      // add userID
      return axiosOrdersInstance.get("v3/orders", { params: { customer: id } });
    },
    onSuccess: async (response) => {
      console.log("Fetched Orders");
      console.log(response.data);
      setOrderData(response.data);
      setIsloading(false);
    },
    onError: async (error) => {
      console.log("Failed to Fetch Orders");
      console.log(error);
    },
  });

  const getCustomer = useMutation({
    mutationFn: (id) => {
      setIsloading(true);
      console.log("Fetching Customer Info. id: ", id);
      return axiosOrdersInstance.get(`v3/customers/${id}`);
    },
    onSuccess: async (response) => {
      console.log("Fetched Customer Info");
      console.log("Customer Info: ", response.data);
      // setOrderData(response.data);
      // setIsloading(false);
    },
    onError: async (error) => {
      console.log("Failed to Fetch Customer Info");
      console.log(error);
    },
  });

  const cancelCustomerOrder = useMutation({
    mutationFn: (id) => {
      // setIsloading(true)
      console.log("Canceling Order");
      // add userID
      return axiosOrdersInstance.put(`v3/orders/${id}`, {
        status: "cancelled",
      });
    },
    onSuccess: async (response) => {
      console.log("Canceled Order");
      console.log(response.data);
      if (user) {
        getOrders.mutate(user.ID);
      }

      // setOrderData(response.data);
      // setIsloading(false);
    },
    onError: async (error) => {
      console.log("Failed to Cancel Order");
      console.log(error);
      setIsloading(false);
    },
  });

  const repurchaseOrder = (orderItems) => {
    if (!alreadyRedirected) {
      setIsOpenedInProductList(true);
      setShowSelector(true);
      return;
    } else {
      const itemIds = orderItems.map((item) => item.product_id);
      getItemsForCart.mutate(itemIds);
      navigate("/cart");
    }
  };

  const cancelOrder = (order) => {
    cancelCustomerOrder.mutate(order.id);
  };

  useEffect(() => {
    console.log("Sign In Effect Called");
    if (!isLoggedIn) {
      navigate("/customer/sign-in");
    }
    if (user) {
      getOrders.mutate(user.ID);
      getCustomer.mutate(user.ID);
    }
  }, [isLoggedIn, user]);

  const [personalInformation, setPersonalInformation] = useState(() => {});

  const handleChange = (section, key, value) => {
    switch (section) {
      case "userInfo":
        setUserInfo((prev) => ({ ...prev, [key]: value }));
        break;
      case "billingInfo":
        setUserBillingInfo((prev) => ({ ...prev, [key]: value }));
        break;
      case "shippingInfo":
        setUserShippingInfo((prev) => ({ ...prev, [key]: value }));
        break;
      default:
        break;
    }
  };

  const handleSignOut = async () => {
    await signOut();
    // Logout logic, which may include page jumps
  };
  const handleIsShippingEdit = async () => {
    await setIsShippingEdit(!isShippingEdit);
    // Logout logic, which may include page jumps
  };
  const handleIsBillingEdit = async () => {
    await setIsBillingEdit(!isBillingEdit);
    // Logout logic, which may include page jumps
  };
  const handleIsCustomerEdit = async () => {
    await setIsCustomerEdit(!isCustomerEdit);
    // Logout logic, which may include page jumps
  };

  const viewOrderSummary = (order) => {
    setViewingOrder(order);
    setActiveTab("summary"); //Set the currently viewed order
  };

  const exitOrderSummary = () => {
    setViewingOrder(null);
    setActiveTab("orders"); //Set the currently viewed order
  };

  const renderOrderSummary = () => {
    if (!viewingOrder) return null;
    return (
      <div className="column-group">
        <div className="column-group-table">
          <div className="column-group-image">
            {viewingOrder.line_items.map((item) => (
              <div key={item.product_id} className="item-image">
                <img className="item-image" src={item.image.src} alt="" />
              </div>
            ))}
          </div>
          <table>
            <tbody>
              <tr>
                <td>Order No.</td>
                <td>{viewingOrder.id}</td>
              </tr>
              <tr>
                <td>Date Placed</td>
                <td>{viewingOrder.date_created}</td>
              </tr>
              <tr>
                <td>Total amount</td>
                <td>${viewingOrder.total}</td>
              </tr>
              <tr>
                <td>Order Status</td>
                <td>{viewingOrder.status}</td>
              </tr>
              <tr>
                <td>Payment Method</td>
                <td>{viewingOrder.payment_method}</td>
              </tr>
            </tbody>
          </table>
          {viewingOrder.status === "completed" && (
            <div className="column-item-action">
              <button onClick={() => repurchaseOrder(viewingOrder.line_items)}>
                BUY AGAIN
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderFilteredOrders = () => {
    // add the correct orders.
    if (filteredOrders.length !== 0) {
      return filteredOrders.map((order) => (
        <div key={order.id} className="column-item">
          <div className="column-item-image">
            {order.line_items.map((item) => (
              <div key={item.product_id} className="item-image">
                <img
                  className="item-image"
                  src={item.image.src}
                  alt=""
                  srcset=""
                />
              </div>
            ))}
          </div>
          <div className="column-item-info">
            {/* <div className="info-group">
                            <h4>Product Name</h4>
                            <span>{order.productName}</span>
                        </div> */}
            <div className="info-group">
              <h4>No.</h4>
              <span>{order.id}</span>
            </div>
            <div className="info-group">
              <h4>Status</h4>
              <span>{order.status}</span>
            </div>
            <div className="info-group">
              <h4>Total</h4>
              <span>${order.total}</span>
            </div>
          </div>
          <div className="column-item-action">
            <button
              style={{
                marginBottom: "0.5rem",
              }}
              onClick={() => viewOrderSummary(order)}
            >
              VIEW
            </button>
            {(order.status === "processing" || order.status === "pending") && (
              <button
                disabled={isloading ? true : false}
                onClick={(event) => {
                  event.preventDefault();
                  cancelOrder(order);
                }}
              >
                {" "}
                {isloading ? "Canceling" : "Cancel"}
              </button>
            )}
          </div>
        </div>
      ));
    } else {
      return <>Sorry there are no Orders with this status</>;
    }
  };

  const saveCustomerData = useMutation({
    mutationFn: (data) => {
      // setIsloading(true)
      console.log("Updating Customer Info");
      // add userID
      return axiosOrdersInstance.put(`v3/customers/${data.id}`, data.data);
    },
    onSuccess: async (response) => {
      console.log("Saved Customer Info");
      console.log(response.data);
      successfullyUpdatedCustomerDetails();

      setUserInfo((prev) => ({ ...prev, ...response.data }));
      localStorage.setItem("userDetails", JSON.stringify(response.data));
      setIsCustomerEdit(false);
    },
    onError: async (error) => {
      console.log("Failed to Save Customer Info");
      console.log(error);
    },
  });

  const successfullyUpdatedCustomerDetails = () =>
    toast.success("Successfully updated.");

  const handleSaveUserInfo = (event) => {
    event.preventDefault();
    console.log("Saving");
    console.log(userInfo);
    const data = {
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
    };
    // combine billing and shipping and send in one package
    saveCustomerData.mutate({ id: userDetails.id, data: data });
  };
  const handleSaveUserBillingInfo = (event) => {
    event.preventDefault();
    console.log("Saving Billing");
    // combine billing and shipping and send in one package
    saveCustomerData.mutate({
      id: userDetails.id,
      data: { billing: userBillingInfo },
    });
  };
  const handleSaveUserShippingInfo = (event) => {
    event.preventDefault();
    console.log("Saving Shipping");
    // combine billing and shipping and send in one package
    saveCustomerData.mutate({
      id: userDetails.id,
      data: { shipping: userShippingInfo },
    });
  };
  const handleReturnToMenu = () => {
    setActiveTab("");
    setMenuClass("account-wrapper");
  };

  const renderContent = () => {
    switch (activeTab) {
      case "summary":
        return (
          <div className="account-main-wrapper">
            <h2>
              Order Summary
              <a onClick={() => exitOrderSummary()}>
                <FontAwesomeIcon icon={faClose} />
              </a>
            </h2>
            {renderOrderSummary()}
          </div>
        );
      case "orders":
        return (
          <div className="account-main-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <h2>Orders</h2>
              <select
                value={status}
                style={{
                  height: "30px",
                  border: "1px solid #173050",
                  backgroundColor: "#FFF",
                  color: "#173050",
                  padding: "0 5px",
                  cursor: "pointer",
                  transition: "all .3s ease",
                  fontWeight: 450,
                  fontFamily: "'Montserrat', sans-serif",
                }}
                onChange={handleOrderStatusChange}
              >
                <option value="all">Status</option>
                {/* <option value="pending">Pending</option> */}
                <option value="processing">Processing</option>
                {/* <option value="pending-payment">Pending Payment</option> */}
                <option value="cancelled">Cancelled</option>
                <option value="completed">completed</option>
                {/* Add more options as needed */}
              </select>
            </div>

            {/* {!isloading ? renderOrders() : "Loading Orders"} */}
            {!isloading ? renderFilteredOrders() : "Loading Filtered Orders"}
          </div>
        );
      case "profile":
        return (
          <div className="account-main-wrapper">
            <Toaster />
            <div className="customer-profile-header">
              <h2>Customer Profile</h2>
              <button onClick={handleIsCustomerEdit}>
                {isCustomerEdit ? "Cancel" : "Edit"}
              </button>
            </div>

            <form onSubmit={(e) => handleSaveUserInfo(e)}>
              <div className="column-group">
                {/* <h3>Personal</h3> */}
                <div className="column-group-item">
                  <label className="column-group-item-label">Email:</label>
                  <input
                    disabled={true}
                    type="text"
                    value={userInfo.email}
                    onChange={(e) =>
                      handleChange("userInfo", "email", e.target.value)
                    }
                  />
                </div>
                <div className="column-group-item">
                  <label className="column-group-item-label">First Name:</label>
                  <input
                    disabled={!isCustomerEdit}
                    type="text"
                    value={userInfo.first_name}
                    onChange={(e) =>
                      handleChange("userInfo", "first_name", e.target.value)
                    }
                  />
                </div>
                <div className="column-group-item">
                  <label className="column-group-item-label">Last Name:</label>
                  <input
                    disabled={!isCustomerEdit}
                    type="text"
                    value={userInfo.last_name}
                    onChange={(e) =>
                      handleChange("userInfo", "last_name", e.target.value)
                    }
                  />
                </div>
                <div className="column-group-item">
                  {isCustomerEdit && (
                    <button style={{ alignSelf: "end" }} type="submit">
                      {!saveCustomerData.isPending ? (
                        "Save"
                      ) : (
                        <ClipLoader
                          loading={saveCustomerData.isPending}
                          size={10}
                          color="#FFF"
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </form>

            <div className="customer-profile-header">
              <h2>Shipping</h2>
              <button onClick={handleIsShippingEdit}>
                {isShippingEdit ? "Cancel" : "Edit"}
              </button>
            </div>
            <form onSubmit={(e) => handleSaveUserShippingInfo(e)}>
              <div className="column-group">
                <div className="column-group-item">
                  <label className="column-group-item-label">Country:</label>
                  <span>Canada</span>
                </div>
                <div className="column-group-item">
                  <label className="column-group-item-label">Province:</label>
                  <span>British Columbia</span>
                </div>
                <div className="column-group-item">
                  <label className="column-group-item-label">Address:</label>
                  <input
                    value={userShippingInfo.address_1}
                    type="text"
                    disabled={!isShippingEdit}
                    onChange={(e) =>
                      handleChange("shippingInfo", "address_1", e.target.value)
                    }
                  />
                </div>
                <div className="column-group-item">
                  <label className="column-group-item-label">City:</label>
                  <input
                    value={userShippingInfo.city}
                    type="text"
                    disabled={!isShippingEdit}
                    onChange={(e) =>
                      handleChange("shippingInfo", "city", e.target.value)
                    }
                  />
                </div>
                {/*<div className="column-group-item">
                                    <label className="column-group-item-label">Country:</label>
                                    <input
                                        value={userShippingInfo.country}
                                        type="text"
                                        disabled={!isShippingEdit}
                                        onChange={(e) => handleChange('shippingInfo', 'country', e.target.value)} />
                                </div>*/}
                <div className="column-group-item">
                  <label className="column-group-item-label">Postcode:</label>
                  <input
                    value={userShippingInfo.postcode}
                    type="text"
                    disabled={!isShippingEdit}
                    onChange={(e) =>
                      handleChange("shippingInfo", "postcode", e.target.value)
                    }
                  />
                </div>
                {/*<div className="column-group-item">
                                    <label className="column-group-item-label">Province/State:</label>
                                    <input
                                        value={userShippingInfo.state}
                                        type="text"
                                        disabled={!isShippingEdit}
                                        onChange={(e) => handleChange('shippingInfo', 'state', e.target.value)} />
                            </div>*/}
                <div className="column-group-item">
                  <label className="column-group-item-label">Phone:</label>
                  <input
                    value={userShippingInfo.phone}
                    type="text"
                    disabled={!isShippingEdit}
                    onChange={(e) =>
                      handleChange("shippingInfo", "phone", e.target.value)
                    }
                  />
                </div>
                <div className="column-group-item">
                  {isShippingEdit && (
                    <button style={{ alignSelf: "end" }} type="submit">
                      {!saveCustomerData.isPending ? (
                        "Save"
                      ) : (
                        <ClipLoader
                          loading={saveCustomerData.isPending}
                          size={10}
                          color="#FFF"
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </form>
            <div className="customer-profile-header">
              <h2>Billing</h2>
              <button onClick={handleIsBillingEdit}>
                {isBillingEdit ? "Cancel" : "Edit"}
              </button>
            </div>
            <form onSubmit={(e) => handleSaveUserBillingInfo(e)}>
              <div className="column-group">
                <div className="column-group-item">
                  <label className="column-group-item-label">Country:</label>
                  <span>Canada</span>
                </div>
                <div className="column-group-item">
                  <label className="column-group-item-label">Province:</label>
                  <span>British Columbia</span>
                </div>
                <div className="column-group-item">
                  <label className="column-group-item-label">Address:</label>
                  <input
                    value={userBillingInfo.address_1}
                    type="text"
                    disabled={!isBillingEdit}
                    onChange={(e) =>
                      handleChange("billingInfo", "address_1", e.target.value)
                    }
                  />
                </div>
                <div className="column-group-item">
                  <label className="column-group-item-label">City:</label>
                  <input
                    value={userBillingInfo.city}
                    type="text"
                    disabled={!isBillingEdit}
                    onChange={(e) =>
                      handleChange("billingInfo", "city", e.target.value)
                    }
                  />
                </div>

                {/*<div className="column-group-item">
                                    <label className="column-group-item-label">Country:</label>
                                    <input
                                        value={userBillingInfo.country}
                                        type="text"
                                        disabled={!isBillingEdit}
                                        onChange={(e) => handleChange('billingInfo', 'country', e.target.value)} />
                        </div>*/}
                <div className="column-group-item">
                  <label className="column-group-item-label">Postcode:</label>
                  <input
                    value={userBillingInfo.postcode}
                    type="text"
                    disabled={!isBillingEdit}
                    onChange={(e) =>
                      handleChange("billingInfo", "postcode", e.target.value)
                    }
                  />
                </div>
                {/*<div className="column-group-item">
                                    <label className="column-group-item-label">Province/State:</label>
                                    <input
                                        value={userBillingInfo.state}
                                        type="text"
                                        disabled={!isBillingEdit}
                                        onChange={(e) => handleChange('billingInfo', 'state', e.target.value)} />
                    </div>*/}
                <div className="column-group-item">
                  <label className="column-group-item-label">Phone:</label>
                  <input
                    value={userBillingInfo.phone}
                    type="text"
                    disabled={!isBillingEdit}
                    onChange={(e) =>
                      handleChange("billingInfo", "phone", e.target.value)
                    }
                  />
                </div>
                <div className="column-group-item">
                  {isBillingEdit && (
                    <button style={{ alignSelf: "end" }} type="submit">
                      {!saveCustomerData.isPending ? (
                        "Save"
                      ) : (
                        <ClipLoader
                          loading={saveCustomerData.isPending}
                          size={10}
                          color="#FFF"
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </form>

            {/* <div className="column-group">
                            <h3>Payment</h3>
                            <div className="column-group-item">
                                <label className="column-group-item-label">Card:</label>
                                {userData.creditCard ? (
                                    <div>
                                        <span>{userData.creditCard.number}</span>
                                    </div>
                                ) : (
                                    <button>Add</button>
                                )}
                                <button>Edit</button>
                            </div>
                        </div> */}
          </div>
        );
      case "discounts":
        return (
          <div className="account-main-wrapper">
            <h2>Gift Cards</h2>
            <div className="column-group">
              {mockGiftCards.map((card) => (
                <div className="column-group-item" key={card.id}>
                  <div className="item-image"></div>
                  <div className="info-group">
                    <h4>Amount</h4>
                    <span>{card.amount}</span>
                  </div>
                  <div className="info-group">
                    <h4>Expire Date</h4>
                    <span>{card.expire}</span>
                  </div>
                  <button>SHOP</button>
                </div>
              ))}
            </div>
            <h2>Coupons</h2>
            <div className="column-group">
              {mockCoupons.map((coupon) => (
                <div className="column-group-item" key={coupon.id}>
                  <div className="item-image"></div>
                  <div className="info-group">
                    <h4>Code</h4>
                    <span>{coupon.code}</span>
                  </div>
                  <div className="info-group">
                    <h4>Discount</h4>
                    <span>{coupon.discount}</span>
                  </div>
                  <div className="info-group">
                    <h4>Expire Date</h4>
                    <span>{coupon.expire}</span>
                  </div>
                  <button>SHOP</button>
                </div>
              ))}
            </div>
          </div>
        );
      case "settings":
        return (
          <div className="account-main-wrapper">
            <h2>Settings</h2>
            <div className="column-group">
              <form>
                <div className="column-group-item">
                  <label
                    className="column-group-item-label"
                    htmlFor="newPassword"
                  >
                    New Password:
                  </label>
                  <input type="password" id="newPassword" name="newPassword" />
                  <button type="submit">Change Password</button>
                </div>
              </form>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="account">
      <div className="account-container">
        <div className="account-header">
          <h2>My Account</h2>
        </div>

        <div className={menuClass}>
          <div className="account-sidebar">
            <ul
              className="account-sidebar-wrapper"
              onClick={() => setMenuClass("account-wrapper hide")}
            >
              <li
                className={
                  activeTab === "orders" || activeTab === "summary"
                    ? "active"
                    : null
                }
                onClick={() => setActiveTab("orders")}
              >
                <span>
                  <FontAwesomeIcon icon={faBox} />
                </span>
                <span>Orders</span>
                <svg viewBox="0 0 24 24" aria-hidden="true" className="r-arrow">
                  <g>
                    <path d="M14.586 12L7.543 4.96l1.414-1.42L17.414 12l-8.457 8.46-1.414-1.42L14.586 12z"></path>
                  </g>
                </svg>
              </li>
              <li
                className={String(activeTab === "profile" && "active")}
                onClick={() => setActiveTab("profile")}
              >
                <span>
                  <FontAwesomeIcon icon={faAddressCard} />
                </span>
                <span>Profile</span>
                <svg viewBox="0 0 24 24" aria-hidden="true" className="r-arrow">
                  <g>
                    <path d="M14.586 12L7.543 4.96l1.414-1.42L17.414 12l-8.457 8.46-1.414-1.42L14.586 12z"></path>
                  </g>
                </svg>
              </li>
              <li
                className={String(activeTab === "discounts" && "active")}
                style={{
                  color: "#999",
                  pointerEvents: "none",
                  cursor: "not-allowed",
                }}
                onClick={() => setActiveTab("discounts")}
              >
                <span>
                  <FontAwesomeIcon icon={faTags} />
                </span>
                <span>Discounts (Coming Soon)</span>
                <svg viewBox="0 0 24 24" aria-hidden="true" className="r-arrow">
                  <g>
                    <path d="M14.586 12L7.543 4.96l1.414-1.42L17.414 12l-8.457 8.46-1.414-1.42L14.586 12z"></path>
                  </g>
                </svg>
              </li>
              <li
                className={String(activeTab === "settings" && "active")}
                onClick={() => setActiveTab("settings")}
              >
                <span>
                  <FontAwesomeIcon icon={faGear} />
                </span>
                <span>Settings</span>
                <svg viewBox="0 0 24 24" aria-hidden="true" className="r-arrow">
                  <g>
                    <path d="M14.586 12L7.543 4.96l1.414-1.42L17.414 12l-8.457 8.46-1.414-1.42L14.586 12z"></path>
                  </g>
                </svg>
              </li>
              <li onClick={handleSignOut}>
                <span>
                  <FontAwesomeIcon icon={faRightFromBracket} />
                </span>
                <span>Sign Out</span>
              </li>
            </ul>
          </div>

          <div className="account-main">
            <button
              className="account-mobile-return"
              onClick={handleReturnToMenu}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Menu
            </button>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
