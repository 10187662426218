import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useCart } from "../../context/CartContext";

import "../../css/ProductItem.css";

const ProductItem = ({ item }) => {
  const {
    removeItemFromCart,
    addItemToCart,
    removeAllItemsOfType,
    calculateItemTotalPrice,
  } = useCart();
  // const unitOfSale = item.meta_data.find(meta => meta.key === "unit_of_sale")?.value;
  return (
    <div className="product-item-container">
      <div className="product-item-info">
        <img className="product-image-cart" src={item.thumbnail} />
        <div className="product-details-column">
          <div className="product-item-name">
            {item.name}
            {item.blog_on_sale && <span className="sale-tag">Sale</span>}
          </div>
          <div className="cart-summary-container">
            <span>${item.blog_price}</span>
            <span className="price-uom">{item.unit}</span>
            {item.blog_on_sale && (
              <span className="regular-price">
                ${item.blog_regular_price}
                <span className="price-uom">{item.unit}</span>
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="cart-quantity-container">
        <div className="">Quantity</div>
        <div className="cart-quantity">
          <div
            className="cart-quantity-button"
            onClick={() => removeItemFromCart(item.id)}
          >
            <FontAwesomeIcon icon={faMinus} />
          </div>
          <div>{item.quantity}</div>
          <div
            className="cart-quantity-button"
            onClick={() => addItemToCart(item)}
          >
            {" "}
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </div>
      </div>
      <div className="cart-total-container">
        <div>Total Price</div>
        <div style={{ fontWeight: "600" }}>
          CA$ {calculateItemTotalPrice(item)}
        </div>
      </div>
      <div
        className="cart-item-delete"
        onClick={() => removeAllItemsOfType(item.id)}
      >
        <FontAwesomeIcon icon={faTrashCan} />
        <span>REMOVE</span>
      </div>
    </div>
  );
};
export default ProductItem;
