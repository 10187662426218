import React, { createContext, useState, useContext, useEffect } from "react";
import {
  axiosUserInstance,
  axiosUserDetailsInstance,
  axiosProductsInstance,
} from "../dataFetch/axios.main";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { set } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { useLocationContext } from "../context/LocationContext";
// create context
const CartContext = createContext();
// customize hook for accessing shopping cart context in components
export const useCart = () => useContext(CartContext);
// provider component
export const CartProvider = ({ children }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [shippingCost, setShippingCost] = useState();
  const [cartItems, setCartItems] = useState(() => {
    // Initialize state from local storage or use a default value
    const storedValue = localStorage.getItem("cart");
    return storedValue ? JSON.parse(storedValue) : [];
  });
  const { selectedLocation, setSelectedLocation } = useLocationContext();
  const ifFirstRender = React.useRef(true);

  // Retrieve product in cart, if the product can't be found anymore, replace the cartItems with new product list
  const checkProductStatus = async () => {
    const ids = cartItems.map((item) => item.id);
    const toastId = toast.loading("Updating cart items...", {
      position: "top-center",
    });
    try {
      const response = await axiosProductsInstance.get("", {
        params: { blog_id: selectedLocation, product_ids: ids },
      });

      const carItemsOfCurrent = response.data;
      const cartItemsOutofstock = carItemsOfCurrent.filter(
        (item) => item.blog_stock_status === "outofstock",
      );

      if (cartItemsOutofstock.length > 0) {
        const newCartItems = cartItems.filter(
          (item) =>
            !cartItemsOutofstock.some((cartItem) => cartItem.id === item.id),
        );

        const updatedCartItems = newCartItems.map((item) => {
          const updatedItem = carItemsOfCurrent.find(
            (product) => product.id === item.id,
          );
          if (updatedItem) {
            return {
              ...item,
              blog_price: updatedItem.blog_price,
              blog_product_id: updatedItem.blog_product_id,
              blog_stock_status: updatedItem.blog_stock_status,
              quantity: item.quantity,
            };
          }
          return item;
        });
        setCartItems(updatedCartItems);
      } else {
        const updatedCartItems = cartItems.map((item) => {
          const updatedItem = carItemsOfCurrent.find(
            (product) => product.id === item.id,
          );
          if (updatedItem) {
            return {
              ...item,
              blog_price: updatedItem.blog_price,
              blog_product_id: updatedItem.blog_product_id,
              blog_stock_status: updatedItem.blog_stock_status,
              quantity: item.quantity,
            };
          }
          return item;
        });
        setCartItems(updatedCartItems);
      }
      toast.success("Cart items updated successfully", { id: toastId });
      console.log("Cart Items Updated");
      console.log("Cart data from:", selectedLocation);
    } catch (error) {
      toast.error("Failed to update cart items", { id: toastId });
      console.log(error);
    }
  };

  useEffect(() => {
    if (ifFirstRender.current) {
      ifFirstRender.current = false;
      return;
    }
    if (selectedLocation !== "") {
      checkProductStatus();
    }
  }, [selectedLocation]);

  // Save cart data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
    if (cartItems.length === 0 && showSideBar) {
      setShowSideBar(false);
    }
  }, [cartItems]);
  // add products to shopping cart
  const addItemToCart = (item) => {
    const existingItemIndex = cartItems.findIndex(
      (cartItem) => cartItem.id === item.id,
    );

    if (existingItemIndex !== -1) {
      // If the item is already in the cart, update the quantity
      setCartItems((prevItems) =>
        prevItems.map((cartItem, index) => {
          if (index === existingItemIndex) {
            const newQuantity = cartItem.quantity + 1;
            return { ...cartItem, quantity: newQuantity };
          }
          return cartItem;
        }),
      );
    } else {
      // If the item is not in the cart, add a new entry
      setCartItems((prevItems) => [...prevItems, { ...item, quantity: 1 }]);
    }
  };

  const getItemsForCart = useMutation({
    mutationFn: (data) => {
      console.log("Fetching Data for Cart Items");
      // const ids = data.join(',');
      // clean this for future
      return axiosProductsInstance.get("", {
        params: { blog_id: selectedLocation, product_ids: data },
      });
    },
    onSuccess: async (response) => {
      console.log("Fetched Data for Cart Items");

      addItemsToCart(response.data);
      // localStorage.setItem('userDetails', JSON.stringify(response.data));
      // setUserDetails(response.data)
    },
    onError: async (error) => {
      console.log("Fail: Fetched Data for Cart Items");
      console.log(error);
    },
  });

  const addItemsToCart = (items) => {
    setCartItems((prevItems) => {
      // Create a copy of the previous items array
      let newItems = [...prevItems];

      // For each item to be added...
      items.forEach((item) => {
        const existingItemIndex = newItems.findIndex(
          (cartItem) => cartItem.id === item.id,
        );

        if (existingItemIndex !== -1) {
          // If the item is already in the cart, update the quantity
          newItems = newItems.map((cartItem, index) =>
            index === existingItemIndex
              ? { ...cartItem, quantity: cartItem.quantity + 1 }
              : cartItem,
          );
        } else {
          // If the item is not in the cart, add a new entry
          newItems = [...newItems, { ...item, quantity: 1 }];
        }
      });

      // Return the updated items array
      return newItems;
    });
  };

  const getItemQuantity = (itemId) => {
    const item = cartItems.find((item) => item.id === itemId);
    return item ? item.quantity : 1;
  };

  const isItemInCart = (itemId) => {
    return cartItems.some((item) => item.id === itemId);
  };

  const updateItemQuantity = (item, quantity) => {
    console.log(quantity);

    const itemIndex = cartItems.findIndex(
      (cartItem) => cartItem.id === item.id,
    );
    // console.log(itemIndex)

    if (itemIndex !== -1) {
      // If the item is already in the cart, update the quantity
      setCartItems((prevItems) =>
        prevItems.map((cartItem, index) =>
          index === itemIndex ? { ...cartItem, quantity: quantity } : cartItem,
        ),
      );
    } else {
      // If the item is not in the cart, add a new entry
      setCartItems((prevItems) => [
        ...prevItems,
        { ...item, quantity: quantity },
      ]);
    }
  };

  // Function to remove an item from the cart
  const removeItemFromCart = (itemId) => {
    setCartItems((prevItems) =>
      prevItems
        .map((item) =>
          item.id === itemId
            ? item.quantity > 1
              ? { ...item, quantity: item.quantity - 1 }
              : null
            : item,
        )
        .filter(Boolean),
    );
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem("cart");
  };

  const getLineItemsForWoo = (items) => {
    return items.map((item) => ({
      blog_product_id: item.blog_product_id,
      master_product_id: item.id,
      quantity: item.quantity,
    }));
  };
  const getLineItemsForCoupon = (items) => {
    return items.map((item) => ({
      product_id: item.id,
      quantity: item.quantity,
    }));
  };

  // Function to remove an item from the cart
  const removeAllItemsOfType = (itemId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  // Function to calculate the total number of items in the cart
  const getTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const calculateTotalCartPrice = () => {
    return cartItems
      .reduce((total, item) => total + item.blog_price * item.quantity, 0)
      .toFixed(2);
  };
  const shippingCostCheckout = () => {
    return shippingCost ? shippingCost.toFixed(2) : 0;
  };
  const estimatedGST = parseFloat(shippingCostCheckout() * 0.05);
  // const estimatedPST = parseFloat(calculateTotalCartPrice() * 0.07);
  const estimatedPST = 0;
  const cartSubTotal =
    parseFloat(calculateTotalCartPrice()) +
    estimatedGST +
    estimatedPST +
    parseFloat(shippingCostCheckout());

  const calculateItemTotalPrice = (item) => {
    const itemTotalPrice = item.blog_price * item.quantity;
    return itemTotalPrice.toFixed(2);
  };

  // Shipping Discount Calculation

  const contextValue = {
    showSideBar,
    setShowSideBar,
    checkProductStatus,
    cartItems,
    addItemToCart,
    addItemsToCart,
    removeItemFromCart,
    removeAllItemsOfType,
    getTotalItems,
    calculateTotalCartPrice,
    calculateItemTotalPrice,
    getLineItemsForWoo,
    getLineItemsForCoupon,
    updateItemQuantity,
    getItemQuantity,
    isItemInCart,
    estimatedGST,
    estimatedPST,
    cartSubTotal,
    clearCart,
    getItemsForCart,
    shippingCost,
    setShippingCost,
    shippingCostCheckout,
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};
