import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faCartShopping,
  faUser,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useCart } from "../../context/CartContext";
import "../../css/OrderSummary.css";
import { NavLink } from "react-router-dom";

const OrderSummary = ({ action }) => {
  const { calculateTotalCartPrice } = useCart();
  return (
    <div className="order-summary">
      <div className="shipping">
        <div className="order-summary-title">Order Summary</div>
      </div>
      <div className="shipping">
        <div className="text-value">Shipping Cost</div>
        <div className="order-value">C$ 5.00</div>
      </div>
      <div className="shipping">
        <div className="text-value">Shipping Discount</div>
        <div className="order-value">C$ 5.00</div>
      </div>
      <div className="shipping">
        <div className="text-value">Sales Tax</div>
        <div className="order-value">C$ 5.00</div>
      </div>
      <div className="shipping">
        <div className="text-value">Estimated Total</div>
        <div className="order-value">C$ {calculateTotalCartPrice()}</div>
      </div>

      <NavLink to="/checkout" end>
        Check Out
      </NavLink>
      <div className="flex flex-row justify-between p-4">
        <div>Promo Code</div>
        <div>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
    </div>
  );
};
export default OrderSummary;
