import React from "react";
import "../css/VideoSection.css";

const VideoSection = () => {
  const videoUrl = "https://www.youtube.com/embed/IMXDxk7LHio";

  return (
    <div className="video-section">
      <div className="video-section-wrapper">
        <div className="video-section-tilte">
          <p className="video-title">Fisherman's Market at Sea</p>
          <p className="video-subtitle">Oceans, Seafood, Life and Us</p>
        </div>
        <iframe
          src={videoUrl}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="Video"
          width="560"
          height="315"
        ></iframe>
      </div>
    </div>
  );
};

export default VideoSection;
