import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CheckoutSummaryItem from "../../components/cart/CheckoutSummaryItem";
import CartContent from "../../components/cart/CartContent";
import { useCart } from "../../context/CartContext";
import { useCheckout } from "../../context/CheckoutContext";
import axios from "axios";
import Stepper from "awesome-react-stepper";
import "../../css/Checkout.css";
import { useMutation } from "@tanstack/react-query";
import { axiosPaymentGatewayInstance } from "../../dataFetch/resourceFetcher";
import { useUser } from "../../context/UserContext";
import PaymentForm from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useStripe } from "@stripe/react-stripe-js";

const CustomerBilling = () => {
  // Contexts
  // const stripePromise = loadStripe('pk_test_51OwXf6AtUiGVvMtLjlsgSkeoC5mzGeVpcQsOnkgTmsp1RCQwSWp82knBTfdqb7OJJ8tFiFgkk2kLBkidqIDicyEb005Joxbt0k');
  const { updateBillingInfo, checkoutOptionsNew, dispatchCheckoutOptions } =
    useCheckout();
  const { isLoggedIn, userDetails } = useUser();
  // console.log("userDetails: ", userDetails)
  // console.log("isLoggedIn: ", isLoggedIn)
  const handleNewCheckoutOptions = (type, payload) => {
    dispatchCheckoutOptions({ type, payload });
  };

  const [billingInfo, setBillingInfo] = useState(() => {
    // const savedPaymentInfo = JSON.parse(localStorage.getItem('checkout-state')).paymentInfo;
    if (isLoggedIn) {
      const savedUserBillingInfo = JSON.parse(
        localStorage.getItem("userDetails"),
      );
      console.log("savedUserBillingInfo: ", savedUserBillingInfo);
      return savedUserBillingInfo
        ? savedUserBillingInfo
        : {
            first_name: "",
            last_name: "",
            address_1: "",
            city: "",
            postcode: "",
            country: "Canada",
            state: "British Columbia",
            email: "",
            phone: "",
          };
    } else {
      const savedGuestBillingInfo = JSON.parse(
        localStorage.getItem("billingInfo"),
      );
      return savedGuestBillingInfo
        ? savedGuestBillingInfo
        : {
            first_name: "",
            last_name: "",
            address_1: "",
            city: "",
            postcode: "",
            country: "Canada",
            state: "British Columbia",
            email: "",
            phone: "",
          };
    }
  });
  const [billingSyncShipping, setBillingSyncShipping] = useState(false);
  const savedUserShippingInfo = JSON.parse(
    localStorage.getItem("shippingInfo"),
  );

  useEffect(() => {
    updateBillingInfo(billingInfo);
  }, [billingInfo]);

  useEffect(() => {
    const savedUserBillingInfo = JSON.parse(
      localStorage.getItem("userDetails"),
    );
    const billing = savedUserBillingInfo
      ? savedUserBillingInfo.billing
      : {
          first_name: "",
          last_name: "",
          address_1: "",
          city: "",
          postcode: "",
          country: "Canada",
          state: "British Columbia",
          phone: "",
        };
    setBillingInfo(billing);
  }, [userDetails]);

  const handleChange = (section, key, target) => {
    switch (section) {
      case "billingInfo":
        if (target.value) target.classList.remove("error");

        setBillingInfo((prev) => ({ ...prev, [key]: target.value }));
        break;
      default:
        break;
    }
  };

  // const options = {
  //     mode: 'payment',
  //     amount: 1099,
  //     currency: 'cad',
  //     // Fully customizable with appearance API.
  //     appearance: {
  //       /*...*/
  //     },
  //   };

  useEffect(() => {
    if (billingSyncShipping) {
      setBillingInfo({
        city: savedUserShippingInfo.city,
        address_1: savedUserShippingInfo.address_1,
        address_2: savedUserShippingInfo.address_2,
        phone: savedUserShippingInfo.phone,
        postcode: savedUserShippingInfo.postcode,
        first_name: savedUserShippingInfo.first_name,
        last_name: savedUserShippingInfo.last_name,
      });
    }
  }, [billingSyncShipping]);

  const handleBillingSyncShipping = (checked) => {
    setBillingSyncShipping(checked);
    if (checked) {
      setBillingInfo({
        city: savedUserShippingInfo.city,
        address_1: savedUserShippingInfo.address_1,
        address_2: savedUserShippingInfo.address_2,
        phone: savedUserShippingInfo.phone,
        postcode: savedUserShippingInfo.postcode,
        first_name: savedUserShippingInfo.first_name,
        last_name: savedUserShippingInfo.last_name,
      });
    } else {
      // Clear the billing information if the checkbox is unchecked
      setBillingInfo({
        city: "",
        address_1: "",
        address_2: "",
        phone: "",
        postcode: "",
        first_name: "",
        last_name: "",
      });
    }
  };

  return (
    <>
      <div className="payment-info-form" id="paymentInfoForm">
        <fieldset className="payment-info-fieldset">
          <legend>Billing Information</legend>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="storePayment"
                checked={checkoutOptionsNew?.storePayment}
                onChange={(e) =>
                  handleNewCheckoutOptions(
                    "SET_STORE_PAYMENT",
                    e.target.checked,
                  )
                }
              />
              Pay in Store
            </label>
            <label className="hidden">
              <input
                type="radio"
                name="onlinePayment"
                checked={checkoutOptionsNew?.onlinePayment}
                onChange={(e) =>
                  handleNewCheckoutOptions(
                    "SET_ONLINE_PAYMENT",
                    e.target.checked,
                  )
                }
                disabled
              />
              Pay Online (coming soon)
            </label>
          </div>
          {checkoutOptionsNew.onlinePayment ? (
            // <Elements stripe={stripePromise}  options={options}>
            //     <PaymentForm />
            // </Elements>
            <div className="column-group">
              <label>
                <input
                  type="checkbox"
                  name="billingSyncShipping"
                  checked={billingSyncShipping}
                  onChange={(e) => handleBillingSyncShipping(e.target.checked)}
                  required
                />
                Same With Delivery Information
              </label>
              {!billingSyncShipping ? (
                <>
                  <div className="column-group-item">
                    <label className="column-group-item-label">Country:</label>
                    <input
                      value={billingInfo?.country}
                      type="text"
                      onChange={(e) =>
                        handleChange("billingInfo", "country", e.target)
                      }
                      required
                    />
                  </div>
                  <div className="column-group-item">
                    <label className="column-group-item-label">Province:</label>
                    <input
                      value={billingInfo?.state}
                      type="text"
                      onChange={(e) =>
                        handleChange("billingInfo", "state", e.target)
                      }
                      required
                    />
                  </div>
                  <div className="column-group-item">
                    <label className="column-group-item-label">Address:</label>
                    <input
                      value={
                        billingSyncShipping
                          ? savedUserShippingInfo?.address_1
                          : billingInfo?.address_1 || ""
                      }
                      type="text"
                      onChange={(e) =>
                        handleChange("billingInfo", "address_1", e.target)
                      }
                      required
                    />
                  </div>
                  <div className="column-group-item">
                    <label className="column-group-item-label">City:</label>
                    <input
                      value={
                        billingSyncShipping
                          ? savedUserShippingInfo?.city
                          : billingInfo?.city || ""
                      }
                      type="text"
                      onChange={(e) =>
                        handleChange("billingInfo", "city", e.target)
                      }
                      required
                    />
                  </div>
                  <div className="column-group-item">
                    <label className="column-group-item-label">Postcode:</label>
                    <input
                      value={
                        billingSyncShipping
                          ? savedUserShippingInfo?.postcode
                          : billingInfo?.postcode || ""
                      }
                      type="text"
                      onChange={(e) =>
                        handleChange("billingInfo", "postcode", e.target)
                      }
                      required
                    />
                  </div>
                  <div className="column-group-item">
                    <label className="column-group-item-label">Phone:</label>
                    <input
                      value={
                        billingSyncShipping
                          ? savedUserShippingInfo?.phone
                          : billingInfo?.phone || ""
                      }
                      type="text"
                      onChange={(e) =>
                        handleChange("billingInfo", "phone", e.target)
                      }
                      required
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            ""
          )}
        </fieldset>
      </div>
    </>
  );
};

export default CustomerBilling;
