import React from "react";
import termsData from "../data/termsData";
import SignUpButton from "../components/SignUpButtom";
import "../css/Terms.css";

export default function Terms() {
  return (
    <div className="terms">
      <div className="terms-banner">
        <h2>Terms & Conditions</h2>
      </div>

      <div className="terms-wrapper" style={{ marginTop: "30px" }}>
        <div className="terms-body">
          <div className="terms-body-section">
            <h3>{termsData.title}</h3>
            {termsData.body.map((section, index) => {
              return (
                <div key={index}>
                  <h4>{section.subtitle}</h4>
                  {section.body.type === "ul" && (
                    <ul>
                      {section.body.content.map((passage, index) => {
                        return (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{ __html: passage }}
                          ></li>
                        );
                      })}
                    </ul>
                  )}
                  {section.body.type === "ol" && (
                    <ol>
                      {section.body.content.map((passage, index) => {
                        return (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{ __html: passage }}
                          ></li>
                        );
                      })}
                    </ol>
                  )}
                  {section.body.type === "p" && (
                    <p>
                      {section.body.content.map((passage, index) => {
                        return (
                          <p
                            key={index}
                            dangerouslySetInnerHTML={{ __html: passage }}
                          ></p>
                        );
                      })}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <SignUpButton />
    </div>
  );
}
