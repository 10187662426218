import React, { useState } from "react";
import OrderSummary from "./OrderSummary";
import ProductItem from "./ProductItem";
import "../../css/CartContent.css";
import { Link } from "react-router-dom";
import Recommends from "../Recommends";
import { useCart } from "../../context/CartContext";
import { useCheckout } from "../../context/CheckoutContext";

const CartContent = () => {
  const { cartItems, calculateTotalCartPrice, checkProductStatus } = useCart();
  const { placeOrder } = useCheckout();

  return (
    <div className="cart-loaded">
      <h2 className="cart-title">Your Cart</h2>

      <div className="cart-content">
        <div className="cart-items-container">
          <div className="cart-product-list">
            {cartItems.map(function (value, index) {
              return <ProductItem key={index} item={value} />;
            })}
          </div>

          {/* <OrderSummary /> */}
        </div>
        <div className="order-total-container">
          <div style={{ fontWeight: "400", marginBottom: "0.5rem" }}>
            Subtotal
          </div>
          <div>CA$ {calculateTotalCartPrice()} </div>
        </div>
        <div className="cart-actions">
          <Link to="/shop" className="cart-continue-button">
            CONTINUE SHOPPING
          </Link>
          <Link to="/checkout" className="cart-checkout-button">
            CHECK OUT
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CartContent;
