import React, { useEffect } from "react";
import Banner from "../components/Banner";
import Sustainability from "../components/Sustainability";
import PriceListBanner from "../components/PriceListBanner";
import FavoriteProducts from "../components/FavoriteProducts";
import VideoSection from "../components/VideoSection";
import PopUp from "../components/PopUp";
import SignUpButtom from "../components/SignUpButtom";
import CustomerReviews from "../components/CustomerReviews";
import FeatureBanner from "../components/FeatureBanner";
import TgBanner from "../components/TgBanner";

// CSS
import "../css/HomePage.css";
import Recommends from "../components/Recommends";

const HomePage = () => {
  // Banner data
  const bannerText1 = "Fisherman's Market";
  const bannerText2 = "Your Trusted leader in BC Seafood";
  const bannerText3 = "Expertly Sourced by Generations of Fishers";
  const bannerButtonText = "SHOP NOW";

  // price list banner data

  const PriceListBannerText1 = "Inside Fisherman's Market";
  const PriceListBannerText2 =
    "From our west coast fishing vessels to our bustling fish markets";
  const PriceListBannerText3 = "A Glimpse into Our Daily Market Operations";
  const PriceListBannerButtonText = "LEARN MORE";

  // VideoSection data
  const videoUrl = "https://www.youtube.com/embed/IMXDxk7LHio";

  return (
    <div className="HomePage">
      <Banner
        text1={bannerText1}
        text2={bannerText2}
        text3={bannerText3}
        buttonText={bannerButtonText}
      />
      <Sustainability />
      <PriceListBanner
        text1={PriceListBannerText1}
        text2={PriceListBannerText2}
        text3={PriceListBannerText3}
        buttonText={PriceListBannerButtonText}
      />
      <FavoriteProducts />
      <FeatureBanner
        title="MONTHLY FEATURE"
        productName="CREW PACKS"
        description="Our Crew Packs are a healthy, ethical, and money-conscious way to get more seafood into your diet. These packs are filled with specially sourced fish that are carefully selected, properly frozen, and vacuum-sealed into convenient portions. Get Selected Crew Packs on Sale all month long."
        desWidth={"500px"}
        bgImage="/images/home_segment_banner_crewpacks.webp"
        location="flex-start"
        linkTo="shop"
        buttonText={"SHOP NOW"}
      />
      <CustomerReviews />
      <VideoSection videoUrl={videoUrl} />
      <PopUp />
      <Recommends />
      <SignUpButtom />
    </div>
  );
};

export default HomePage;
