import { React, useState, useEffect } from "react";
import { axiosMailChimpInstance } from "../mutations/checkout";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";

import "../css/PopUp.css";
import popupImage from "../images/popup.jpg";
import popupLogo from "../images/FMS_horizontal-logo-RGB-single-colour.svg";
import image1 from "../images/commercial_fishing_2.jpg";
import { set } from "react-hook-form";

const PopUp = () => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [resMessage, setResMessage] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0]; // 'YYYY-MM-DD'
    const visitedDays = JSON.parse(localStorage.getItem("visitedDays")) || [];
    const subscribed =
      JSON.parse(localStorage.getItem("isSubscribed")) || false;
    const popupShownInSession = sessionStorage.getItem("popupShown");
    const loggedIn = JSON.parse(localStorage.getItem("isLoggedIn")) || false;

    setIsSubscribed(subscribed);
    setIsLoggedIn(loggedIn);

    if (!subscribed && !popupShownInSession) {
      if (!visitedDays.includes(today)) {
        // New unique visit day
        visitedDays.push(today);
        localStorage.setItem("visitedDays", JSON.stringify(visitedDays));
      }

      const uniqueVisitCount = visitedDays.length;

      // Show popup on 1st and every 4th unique visit day
      if (uniqueVisitCount === 1 || uniqueVisitCount % 4 === 0) {
        setTimeout(() => {
          //setShowPopup(true);
        }, 10000);
      }
    }
  }, []);

  const handleUserName = (event) => {
    setUserName(event.target.value);
    setEmptyName(false);
  };
  const handleUserEmail = (event) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(event.target.value);

    if (isValidEmail || event.target.value === "") {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
    setUserEmail(event.target.value);
    setEmptyEmail(false);
  };

  const handleRegistration = (event) => {
    event.preventDefault();
    if (!userName || !userEmail) {
      if (!userName) {
        setEmptyName(true);
      }
      if (!userEmail) {
        setEmptyEmail(true);
      }
      return;
    }
    registerGuestToMailChimp();
  };

  const registerGuestToMailChimp = async () => {
    console.log("data before subscribe: ", userName, userEmail);
    const data = {
      email: userEmail,
      name: userName,
      termsAccepted: true,
    };
    try {
      setIsLoading(true);
      const res = await axiosMailChimpInstance.post(
        "/mailchimp/register",
        data,
      );
      if (res.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setResMessage(res.data.message);
        console.log("Successfully subscribed.");
        console.log(res);
        setTimeout(() => {
          localStorage.setItem("isSubscribed", JSON.stringify(true));
          setIsSubscribed(true);
          setIsSuccess(false);
        }, 2000);
      }
      // else if (res.status === 400 && res.data.code === 'mailchimp_permanently_deleted') {
      //   // user has been permenantly deleted, have to use the MailChimp portal to re-subscribe
      //   window.location.href = 'http://eepurl.com/haDB35';
      // }
      else {
        // console.log('Failed to subscribe.');
        setIsLoading(false);
        setIsSuccess(true);
        setResMessage(res.data.message);
        setTimeout(() => {
          setIsSuccess(false);
        }, 4000);
      }
    } catch (error) {
      setIsLoading(false);
      setIsSuccess(true);
      setResMessage(error.response.data.message);
      if (
        error.response.status === 400 &&
        error.response.data.code === "mailchimp_subscribed"
      ) {
        localStorage.setItem("isSubscribed", JSON.stringify(true));
      }
      setTimeout(() => {
        if (
          error.response.status === 400 &&
          error.response.data.code === "mailchimp_subscribed"
        ) {
          setIsSubscribed(true);
        }
        setIsSuccess(false);
      }, 4000);
    }
  };

  const handleClosePopUp = () => {
    setShowPopup(false);
    sessionStorage.setItem("popupShown", true);
  };

  return (
    <>
      {showPopup && !isSubscribed && !isLoggedIn && (
        <div className="popup">
          {isLoading ? (
            <div className="popup-loading">
              <ClipLoader size={30} color="#173050" data-testid="loader" />
              <p>Signing up</p>
            </div>
          ) : isSuccess ? (
            <div className="popup-loading">
              <p>{resMessage}</p>
            </div>
          ) : (
            <div className="popup-inner">
              <button className="close-button" onClick={handleClosePopUp}>
                ×
              </button>
              <div className="popup-wrapper">
                <div className="popup-content">
                  <div className="popup-logo">
                    <img src={popupLogo} />
                  </div>
                  <div className="popup-image">
                    <div
                      className="popup-image-container"
                      style={{ backgroundImage: `url(${image1})` }}
                    ></div>
                    <div
                      className="popup-image-container"
                      style={{
                        backgroundImage:
                          "url(/images/blog/FishermansMarket_BCCFW_lingcod_sustainable_fishing_AdamPearl.webp)",
                      }}
                    ></div>
                  </div>
                  <p>Sign up and receive</p>
                  <span className="popup-content-sale">20% Off</span>
                  <p>your first order</p>
                  <div className="popup-input">
                    <input
                      type="text"
                      value={userName}
                      onChange={handleUserName}
                      className={emptyName ? "empty" : undefined}
                      placeholder="Name"
                    />
                  </div>
                  <div className="popup-input">
                    <input
                      type="email"
                      value={userEmail}
                      onChange={handleUserEmail}
                      className={emptyEmail ? "empty" : undefined}
                      placeholder="Email"
                    />
                    {!isValidEmail && (
                      <span className="invalid-email-msg">
                        Please enter a valid email address.
                      </span>
                    )}
                  </div>
                  <button
                    className="popup-signup-button"
                    onClick={handleRegistration}
                  >
                    SIGN ME UP
                  </button>
                  <p
                    style={{
                      fontWeight: "400",
                      width: "70%",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    Signing up keeps you informed on weekly blowout sales,
                    upcoming seafood events, giveaways and more
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PopUp;
